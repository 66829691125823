/* eslint-disable react/button-has-type */
import React from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  primary?: boolean;
  secondary?: boolean;
  light?: boolean;
  loading?: boolean;
}

const primaryClass =
  'bg-red-600 hover:bg-red-700  text-white focus:ring-red-500';
const secondaryClass =
  'text-red-700 bg-red-100 text-gray-700 hover:bg-red-200 focus:ring-red-500';
const lightClass =
  'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-red-500';
const xsClass = 'text-xs';
const smClass = 'text-xs leading-4';
const mdClass = 'text-sm';
const lgClass = 'text-base';
const xlClass = 'text-base';

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  type = 'button',
  primary,
  secondary,
  light,
  size = 'md',
  disabled,
  loading,
  ...props
}) => {
  return (
    <button
      className={classNames(
        `inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          primary && primaryClass
        } ${secondary && secondaryClass} ${light && lightClass} ${
          size === 'xs' && xsClass
        } ${size === 'sm' && smClass} ${size === 'md' && mdClass} ${
          size === 'lg' && lgClass
        } ${size === 'xl' && xlClass}`,
        className || '',
        disabled ? 'opacity-50' : '',
      )}
      type={type}
      disabled={disabled}
      {...props}
    >
      {children}
      {loading && (
        <svg
          className='animate-spin ml-2 mr-3 h-6 w-6 text-black'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
        >
          <circle
            className='opacity-25'
            cx='12'
            cy='12'
            r='10'
            stroke='currentColor'
            strokeWidth='4'
          />
          <path
            className='opacity-75'
            fill='currentColor'
            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
          />
        </svg>
      )}
    </button>
  );
};

export default Button;

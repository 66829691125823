import { RefObject } from 'react';

export const resolveDay = (day: number): string => {
  const defaultDay = 'maandag';

  switch (day) {
    case 0:
      return 'zondag';
    case 1:
      return 'maandag';
    case 2:
      return 'dinsdag';
    case 3:
      return 'woensdag';
    case 4:
      return 'donderdag';
    case 5:
      return 'vrijdag';
    case 6:
      return 'zaterdag';
    default:
      return defaultDay;
  }
};

export const resolveEndOfPeriod = (
  day: number,
  duration: number,
): string | undefined => {
  if (day && duration) {
    let end = day + duration - 1;
    if (end > 6) {
      end -= 7;
    }

    return resolveDay(end);
  }
  return undefined;
};

export const getRefElement = <T>(
  element?: RefObject<Element> | T,
): Element | T | undefined | null => {
  if (element && typeof element === 'object' && 'current' in element) {
    return element.current;
  }

  return element;
};

export const isSSR = !(
  typeof window !== 'undefined' && window.document?.createElement
);

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useCallback, useState } from 'react';
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';

export interface IFilter {
  id: string;
  name: string;
  options: {
    value: number;
    label: string;
    checked: boolean;
  }[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface Props {
  filters: IFilter[];
  selectedFilters: number[];
  addFilter: (id: number) => void;
  removeFilter: (id: number) => void;
}

const Filters: React.FC<Props> = ({
  addFilter,
  removeFilter,
  filters,
  selectedFilters,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const getSelectedNumber = (filter: IFilter) => {
    let count = 0;

    filter.options.forEach(item => {
      if (item.checked) count += 1;
    });
    return count;
  };

  const getActiveFilters = useCallback(() => {
    const activeFilters: {
      value: number;
      label: string;
    }[] = [];

    for (let i = 0; i < filters.length; i += 1) {
      const currentFilter = filters[i];

      currentFilter.options.forEach(option => {
        if (
          typeof option.value === 'number' &&
          selectedFilters.includes(option.value)
        ) {
          activeFilters.push({
            value: option.value,
            label: option.label,
          });
        }
      });
    }

    return activeFilters;
  }, [filters, selectedFilters]);

  return (
    <div className='bg-white'>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 flex z-10 sm:hidden'
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='translate-x-full'
          >
            <div className='ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto'>
              <div className='px-4 flex items-center justify-between'>
                <h2 className='text-lg font-medium text-gray-900'>Filters</h2>
                <button
                  type='button'
                  className='-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400'
                  onClick={() => setOpen(false)}
                >
                  <span className='sr-only'>Close menu</span>
                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>

              {/* Filters */}
              <form className='mt-4'>
                {filters.map(section => (
                  <Disclosure
                    as='div'
                    key={section.name}
                    className='border-t border-gray-200 px-4 py-6'
                  >
                    {({ open: openMenu }) => (
                      <>
                        <h3 className='-mx-2 -my-3 flow-root'>
                          <Disclosure.Button className='px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400'>
                            <span className='font-medium text-gray-900'>
                              {section.name}
                            </span>
                            <span className='ml-6 flex items-center'>
                              <ChevronDownIcon
                                className={classNames(
                                  openMenu ? '-rotate-180' : 'rotate-0',
                                  'h-5 w-5 transform',
                                )}
                                aria-hidden='true'
                              />
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className='pt-6'>
                          <div className='space-y-6'>
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className='flex items-center'
                              >
                                <input
                                  id={`filter-mobile-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type='checkbox'
                                  defaultChecked={option.checked}
                                  onChange={e => {
                                    const { target } = e;
                                    const { checked } = target;

                                    if (checked) addFilter(option.value);
                                    else removeFilter(option.value);
                                  }}
                                  className='h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500'
                                />
                                <label
                                  htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                  className='ml-3 text-sm text-gray-500'
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Filters */}
      <section aria-labelledby='filter-heading'>
        <h2 id='filter-heading' className='sr-only'>
          Filters
        </h2>

        <div className='relativ bg-white border-b border-gray-200 pb-4'>
          <div className='max-w-7xl mx-auto flex items-center justify-between'>
            <div className='flex space-x-3'>{children}</div>
            <button
              type='button'
              className='inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden'
              onClick={() => setOpen(true)}
            >
              Filters
            </button>

            <div className='hidden sm:block'>
              <div className='flow-root'>
                <Popover.Group className='-mx-4 flex items-center divide-x divide-gray-200'>
                  {filters.map((section, sectionIdx) => (
                    <Popover
                      key={section.name}
                      className='px-4 relative inline-block text-left'
                    >
                      <Popover.Button className='group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900'>
                        <span>{section.name}</span>
                        {getSelectedNumber(section) > 0 ? (
                          <span className='ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums'>
                            {getSelectedNumber(section)}
                          </span>
                        ) : null}
                        <ChevronDownIcon
                          className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                          aria-hidden='true'
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Popover.Panel className='origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <form className='space-y-4'>
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className='flex items-center'
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type='checkbox'
                                  defaultChecked={option.checked}
                                  onChange={e => {
                                    const { target } = e;
                                    const { checked } = target;

                                    if (checked) addFilter(option.value);
                                    else removeFilter(option.value);
                                  }}
                                  className='h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500'
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className='ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap'
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>

        {/* Active filters */}
        {getActiveFilters().length > 0 && (
          <div className='bg-gray-100'>
            <div className='max-w-7xl mx-auto py-3 px-4 sm:flex sm:items-center sm:px-6 lg:px-8'>
              <h3 className='text-xs font-semibold uppercase tracking-wide text-gray-500'>
                Filters
                <span className='sr-only'>, active</span>
              </h3>

              <div
                aria-hidden='true'
                className='hidden w-px h-5 bg-gray-300 sm:block sm:ml-4'
              />

              <div className='mt-2 sm:mt-0 sm:ml-4'>
                <div className='-m-1 flex flex-wrap items-center'>
                  {getActiveFilters().map(activeFilter => (
                    <span
                      key={activeFilter.value}
                      className='m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-sm font-medium bg-white text-gray-900'
                    >
                      <span>{activeFilter.label}</span>
                      <button
                        type='button'
                        className='flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500'
                        onClick={() => {
                          removeFilter(activeFilter.value);
                        }}
                      >
                        <span className='sr-only'>
                          Remove filter for {activeFilter.label}
                        </span>
                        <svg
                          className='h-2 w-2'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 8 8'
                        >
                          <path
                            strokeLinecap='round'
                            strokeWidth='1.5'
                            d='M1 1l6 6m0-6L1 7'
                          />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export { Filters };

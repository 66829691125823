import { gql } from '@apollo/client';

export const GET_PAYMENT = gql`
  query findOnePayment($where: PaymentWhereUniqueInput) {
    findOnePayment(where: $where) {
      id
      createdAt
      updatedAt
      status
      booking {
        user {
          profile {
            id
            firstName
            lastName
            phone
            mobile
          }
        }
        unit {
          id
          title
        }
        period {
          id
          name
        }
        start
        end
        price
      }
      paymentId
      invoiceId
      isPaid
      vatAmount
      vatPercentage
      type {
        id
        label
        slug
      }
      includesGuarantee
      guaranteeAmount
      setPaidOnCreaction
    }
  }
`;

export const GET_PAYMENTS = gql`
  query findManyPayments($query: PaymentFindArgs) {
    findManyPayments(query: $query) {
      id
      createdAt
      updatedAt
      status
      booking {
        user {
          profile {
            id
            firstName
            lastName
            phone
            mobile
          }
        }
        unit {
          id
          title
        }
        period {
          id
          name
        }
        start
        end
        price
      }

      paymentId
      invoiceId
      isPaid
      vatAmount
      vatPercentage
      includesGuarantee
      guaranteeAmount
      setPaidOnCreaction
      type {
        id
        label
        slug
      }
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation createPayment($data: PaymentCreateInput) {
    createPayment(data: $data) {
      id
      createdAt
      updatedAt
      status
      booking {
        id
        user {
          profile {
            id
            firstName
            lastName
            phone
            mobile
          }
        }
        unit {
          id
          title
        }
        period {
          id
          name
        }
        start
        end
        price
      }
      paymentId
      invoiceId
      creditId
      refundId
      checkoutId
      isRefunded
      isPaid
      canceled
      vatAmount
      vatPercentage
      includesGuarantee
      guaranteeAmount
      setPaidOnCreaction
      type {
        id
        label
        slug
      }
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation updatePayment(
    $data: PaymentUpdateInput
    $where: PaymentWhereUniqueInput
  ) {
    updatePayment(data: $data, where: $where) {
      id
      createdAt
      updatedAt
      status
      booking {
        id
        user {
          profile {
            id
            firstName
            lastName
            phone
            mobile
          }
        }
        unit {
          id
          title
        }
        period {
          id
          name
        }
        start
        end
        price
      }

      paymentId
      invoiceId
      creditId
      refundId
      checkoutId
      isRefunded
      isPaid
      canceled
      vatAmount
      vatPercentage
      includesGuarantee
      guaranteeAmount
      setPaidOnCreaction
      type {
        id
        label
        slug
      }
    }
  }
`;

export const REFUND_PAYMENT = gql`
  mutation createPartialRefund($data: CreatePartialRefundInput) {
    createPartialRefund(data: $data) {
      id
      createdAt
      updatedAt
      status
      booking {
        id
        user {
          profile {
            id
            firstName
            lastName
            phone
            mobile
          }
        }
        unit {
          id
          title
        }
        period {
          id
          name
        }
        start
        end
        price
      }

      paymentId
      invoiceId
      creditId
      refundId
      checkoutId
      isRefunded
      isPaid
      canceled
      vatAmount
      vatPercentage
      includesGuarantee
      guaranteeAmount
      setPaidOnCreaction
      type {
        id
        label
        slug
      }
    }
  }
`;

export const DELETE_PAYMENT = gql`
  mutation deletePayment($where: PaymentWhereUniqueInput) {
    deletePayment(where: $where) {
      count
    }
  }
`;

export const DELETE_PAYMENTS = gql`
  mutation deletePayments($where: PaymentWhereInput) {
    deletePayments(where: $where) {
      count
    }
  }
`;

export const CREATE_STRIPE_PAYMENT = gql`
  mutation createPaymentStripe($where: PaymentWhereUniqueInput!) {
    createPaymentStripe(where: $where) {
      id
      createdAt
      updatedAt
      status
      booking {
        id
        user {
          profile {
            id
            firstName
            lastName
            phone
            mobile
          }
        }
        unit {
          id
          title
        }
        period {
          id
          name
        }
        start
        end
        price
      }

      paymentId
      invoiceId
      creditId
      refundId
      checkoutId
      isRefunded
      isPaid
      canceled
      vatAmount
      vatPercentage
      includesGuarantee
      guaranteeAmount
      setPaidOnCreaction
      type {
        id
        label
        slug
      }
    }
  }
`;

export const RESEND_GUARANTEE = gql`
  mutation resendGuarantee($where: PaymentWhereUniqueInput!) {
    resendGuarantee(where: $where) {
      id
      createdAt
      updatedAt
      status
      booking {
        id
        user {
          profile {
            id
            firstName
            lastName
            phone
            mobile
          }
        }
        unit {
          id
          title
        }
        period {
          id
          name
        }
        start
        end
        price
      }

      paymentId
      invoiceId
      creditId
      refundId
      checkoutId
      isRefunded
      isPaid
      canceled
      vatAmount
      vatPercentage
      includesGuarantee
      guaranteeAmount
      setPaidOnCreaction
      type {
        id
        label
        slug
      }
    }
  }
`;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faPen,
  faTrash,
  faTimes,
  faCheck,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import { Disclosure, Transition } from '@headlessui/react';
import _ from 'lodash';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { SearchIcon, TrashIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Button } from '../components/button';
import { Input } from '../components/input';
import { Textarea } from '../components/textarea';
import { INavItem } from '../components/navbar';
import { Slideover } from '../components/slideover';
import { Notification } from '../components/notification';

import { IUnit, IUnitPeriod, IPeriod, IExternalBookingSource } from '../types';
import {
  GET_UNITS,
  CREATE_UNIT,
  UPDATE_UNIT,
  DELETE_UNIT,
} from '../graphql/unit';
import { GET_PERIODS } from '../graphql/period';
import useDebounce from '../components/hooks/useDebounce';
import { Table, IColumn } from '../components/table';
import { Combobox, ComboboxItem } from '../components/comboBox';

import { PageHeader } from '../components/pageheader';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface ICreateUpdate {
  unit?: IUnit;
  save?: () => void;
  cancel?: () => void;
  handleChange?: React.Dispatch<React.SetStateAction<IUnit | undefined>>;
}

const CreateUpdateForm: React.FC<ICreateUpdate> = ({
  unit,
  save = () => ({}),
  cancel = () => ({}),
  handleChange = () => ({}),
}) => {
  const [tempUnitValue, setTempUnitValue] = useState<string>('');

  const debouncedValue = useDebounce(tempUnitValue, 500);

  const getSelectedUnitFilter = () => {
    if (unit && unit.children && unit.children.length > 0) {
      const selectedUnits = unit.children;
      const query: any = { NOT: [] };

      for (let i = 0; i < selectedUnits.length; i += 1) {
        const selectedUnit = selectedUnits[i];

        if (selectedUnit.id) {
          query.NOT.push({ id: { equals: selectedUnit.id } });
        }
      }

      return query;
    }

    return {};
  };

  const { data } = useQuery(GET_UNITS, {
    variables: {
      query: {
        where: {
          OR: [{ title: { contains: debouncedValue } }],
          ...getSelectedUnitFilter(),
        },
      },
    },
  });

  const { data: periodData } = useQuery(GET_PERIODS, {
    variables: {
      query: {
        take: 100,
      },
    },
  });

  const onChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { target } = event;
    const { value, id } = target;

    handleChange(prevState => {
      let updatedUnit: IUnit | undefined = _.cloneDeep(prevState);

      if (!updatedUnit) updatedUnit = {};

      updatedUnit = {
        ...updatedUnit,
        [id]: value,
      };
      return updatedUnit;
    });
  };

  const toggleDeleteChild = (index: number) => {
    handleChange(prevState => {
      const updatedUnit: IUnit | undefined = _.cloneDeep(prevState);

      if (updatedUnit && updatedUnit.children && updatedUnit.children[index]) {
        if (updatedUnit.children[index] && index > -1) {
          updatedUnit.children[index].deleteChild =
            !updatedUnit.children[index].deleteChild;
        }
        return updatedUnit;
      }

      return prevState;
    });
  };

  const addCurrentSelection = useCallback(
    (item: ComboboxItem) => {
      handleChange(prevState => {
        const updatedUnit: IUnit | undefined = _.cloneDeep(prevState);

        const units =
          data && data.findManyUnits ? data.findManyUnits : undefined;

        if (units) {
          let unitSource: IUnit | undefined;
          for (let i = 0; i < units.length; i += 1) {
            const currentUnit = units[i];

            if (currentUnit.id === item.id) {
              unitSource = currentUnit;
            }
          }

          if (unitSource) {
            if (unitSource && unitSource.title && unitSource.id) {
              const newChild: IUnit = {
                new: true,
                title: unitSource.title,
                id: unitSource.id,
              };

              if (updatedUnit && updatedUnit.children) {
                updatedUnit.children = [...updatedUnit.children, newChild];

                return updatedUnit;
              }
              if (updatedUnit) {
                updatedUnit.children = [newChild];
                return updatedUnit;
              }
            }
          }
        }

        return prevState;
      });
    },
    [handleChange, data],
  );

  const parseOptions = (units: IUnit[]) => {
    const parsedUnits: ComboboxItem[] = [];

    for (let i = 0; i < units.length; i += 1) {
      const currentUnit = units[i];
      if (currentUnit.id && currentUnit.title)
        parsedUnits.push({ id: currentUnit.id, name: currentUnit.title });
    }

    return parsedUnits;
  };

  const parsePeriodOptions = (periods: IPeriod[]) => {
    const parsedPeriods: ComboboxItem[] = [];

    for (let i = 0; i < periods.length; i += 1) {
      const period = periods[i];
      if (period.id && period.name)
        parsedPeriods.push({ id: period.id, name: period.name });
    }

    return parsedPeriods;
  };

  const unitPeriodsSeason = (
    season: number,
    unitPeriods: IUnitPeriod[] | undefined,
  ) => {
    if (unitPeriods) {
      const unitPeriodArray = unitPeriods.filter(item => {
        return item.season && item.season.id === season;
      });

      return unitPeriodArray;
    }
    return [];
  };

  const laagSeizoen = unit ? unitPeriodsSeason(1, unit.unitPeriods) : [];
  const hoogSeizoen = unit ? unitPeriodsSeason(2, unit.unitPeriods) : [];

  const updateUnitPeriod = useCallback(
    (field: 'price' | 'period', value: string | number, id: number) => {
      handleChange(prevState => {
        const currentUnit: IUnit | undefined = _.cloneDeep(prevState);

        if (field === 'price') {
          const price = Number(value);

          const unitPeriods = currentUnit ? currentUnit.unitPeriods : undefined;

          if (unitPeriods && unitPeriods.length > 0) {
            for (let i = 0; i < unitPeriods.length; i += 1) {
              const unitPeriod = unitPeriods[i];
              if (
                unitPeriod.id === id &&
                currentUnit &&
                currentUnit.unitPeriods
              ) {
                currentUnit.unitPeriods[i].dirty = true;
                currentUnit.unitPeriods[i].price = price;
              }
            }

            return currentUnit;
          }
        } else if (field === 'period') {
          if (periodData && periodData.findManyPeriods) {
            let currentPeriod: IPeriod | undefined;

            for (let i = 0; i < periodData.findManyPeriods.length; i += 1) {
              const period = periodData.findManyPeriods[i];

              if (period && period.id === value) {
                currentPeriod = period;
              }
            }

            const unitPeriods = currentUnit?.unitPeriods;
            if (currentPeriod && unitPeriods) {
              let matchedIndex: number | undefined;
              for (let i = 0; i < unitPeriods.length; i += 1) {
                const unitPeriod = unitPeriods[i];

                if (unitPeriod && unitPeriod.id === id) {
                  matchedIndex = i;
                }
              }

              if ((matchedIndex || matchedIndex === 0) && currentUnit) {
                // console.log(matchedIndex, unitPeriods[matchedIndex]);
                unitPeriods[matchedIndex].dirty = true;
                unitPeriods[matchedIndex] = {
                  ...unitPeriods[matchedIndex],
                  period: currentPeriod,
                };

                currentUnit.unitPeriods = unitPeriods;

                return currentUnit;
              }
            }
          }
        }

        return undefined;
      });
    },
    [handleChange, periodData],
  );

  const addUnitPeriod = useCallback(
    (season: number) => {
      handleChange(prevState => {
        const newState: IUnit | undefined = _.cloneDeep(prevState);
        const unitPeriods = newState ? newState.unitPeriods : undefined;

        if (unitPeriods && prevState && newState) {
          const newPeriod: IUnitPeriod = {
            id: moment().unix(),
            unit: prevState,
            season: {
              id: season,
            },
            price: 0,
            new: true,
          };

          unitPeriods.push(newPeriod);

          newState.unitPeriods = unitPeriods;
        }
        return newState;
      });
    },
    [handleChange],
  );

  const deleteItem = (itemID: number | string) => {
    handleChange(prevState => {
      const currentUnit: IUnit | undefined = _.cloneDeep(prevState);

      const unitPeriods = currentUnit ? currentUnit.unitPeriods : undefined;

      if (unitPeriods && unitPeriods.length > 0) {
        for (let i = 0; i < unitPeriods.length; i += 1) {
          const unitPeriod = unitPeriods[i];
          if (
            unitPeriod.id === itemID &&
            currentUnit &&
            currentUnit.unitPeriods
          ) {
            currentUnit.unitPeriods[i].dirty = true;
            currentUnit.unitPeriods[i].delete = true;
          }
        }
      }

      return currentUnit;
    });
  };

  const addExternalSource = () => {
    handleChange(prevState => {
      let updatedUnit: IUnit | undefined = _.cloneDeep(prevState);
      const defaultSource: IExternalBookingSource = {
        id: moment().unix(),
        title: 'nieuwe bron',
        new: true,
        edit: true,
      };

      if (updatedUnit && updatedUnit.externalSources) {
        updatedUnit.externalSources.push(defaultSource);
      } else if (updatedUnit) {
        updatedUnit.externalSources = [defaultSource];
      }

      updatedUnit = {
        ...updatedUnit,
      };
      return updatedUnit;
    });
  };

  const toggleEditExternalSource = (key: any) => {
    handleChange(prevState => {
      let updatedUnit: IUnit | undefined = _.cloneDeep(prevState);

      if (updatedUnit && updatedUnit.externalSources) {
        if (updatedUnit.externalSources[key]) {
          updatedUnit.externalSources[key].edit =
            !updatedUnit.externalSources[key].edit;
        }
      }

      updatedUnit = {
        ...updatedUnit,
      };
      return updatedUnit;
    });
  };

  const onChangeSourceTitle = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: any,
  ) => {
    const { target } = event;
    const { value } = target;

    handleChange(prevState => {
      let updatedUnit: IUnit | undefined = _.cloneDeep(prevState);

      if (updatedUnit && updatedUnit.externalSources) {
        if (updatedUnit.externalSources[key]) {
          updatedUnit.externalSources[key].title = value;
          updatedUnit.externalSources[key].dirty = true;
        }
      }

      updatedUnit = {
        ...updatedUnit,
      };
      return updatedUnit;
    });
  };

  const onChangeSourceURL = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: any,
  ) => {
    const { target } = event;
    const { value } = target;

    handleChange(prevState => {
      let updatedUnit: IUnit | undefined = _.cloneDeep(prevState);

      if (updatedUnit && updatedUnit.externalSources) {
        if (updatedUnit.externalSources[key]) {
          updatedUnit.externalSources[key].url = value;
          updatedUnit.externalSources[key].dirty = true;
        }
      }

      updatedUnit = {
        ...updatedUnit,
      };
      return updatedUnit;
    });
  };

  const deleteExternalSource = (key: any) => {
    handleChange(prevState => {
      let updatedUnit: IUnit | undefined = _.cloneDeep(prevState);

      if (updatedUnit && updatedUnit.externalSources) {
        if (updatedUnit.externalSources[key]) {
          updatedUnit.externalSources[key].delete =
            !updatedUnit.externalSources[key].delete;
          updatedUnit.externalSources[key].dirty = true;
        }
      }

      updatedUnit = {
        ...updatedUnit,
      };
      return updatedUnit;
    });
  };

  return (
    <form className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
      {/* Unit Name */}
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Naam: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='title'
            id='title'
            value={unit && unit.title ? unit.title : ''}
            onChange={onChangeInput}
            type='text'
          />
        </div>
      </div>
      {/* Unit Description */}
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='body'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Beschrijving:{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Textarea
            name='body'
            id='body'
            value={unit && unit.body ? unit.body : ''}
            onChange={onChangeInput}
          />
        </div>
      </div>
      {/* Unit deposit */}
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='body'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Voorschot:{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='depositAmount'
            id='depositAmount'
            type='number'
            value={unit && unit.depositAmount ? unit.depositAmount : ''}
            onChange={onChangeInput}
          />
        </div>
      </div>
      {/* Unit guarantee */}
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='body'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Waarborg:{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='guaranteeAmount'
            id='guaranteeAmount'
            type='number'
            value={unit && unit.guaranteeAmount ? unit.guaranteeAmount : ''}
            onChange={onChangeInput}
          />
        </div>
      </div>
      {/* Unit min persons */}
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='minPersons'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Min. aantal personen: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='minPersons'
            id='minPersons'
            type='number'
            value={unit && unit.minPersons ? unit.minPersons : ''}
            onChange={onChangeInput}
          />
        </div>
      </div>
      {/* Unit max persons */}
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='maxPersons'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Max. aantal personen: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='maxPersons'
            id='maxPersons'
            type='number'
            value={unit && unit.maxPersons ? unit.maxPersons : ''}
            onChange={onChangeInput}
          />
        </div>
      </div>

      {unit && unit.id && (
        <>
          <div>
            <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
              <div>
                <label
                  htmlFor=''
                  className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
                >
                  {' '}
                  Onderliggende units:{' '}
                </label>
              </div>
              <div className='sm:col-span-2'>
                <Combobox
                  items={
                    data && data.findManyUnits
                      ? parseOptions(data.findManyUnits)
                      : []
                  }
                  // selected={tempUnitSelection}
                  query={tempUnitValue}
                  setQuery={value => {
                    setTempUnitValue(value);
                    // setTempUnitSelection(undefined);
                  }}
                  setSelected={value => {
                    // setTempUnitValue("");
                    addCurrentSelection(value);
                  }}
                  placeholder='zoeken...'
                />
              </div>
            </div>
            {unit.children && (
              <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:pb-5'>
                <div className='col-start-2 col-span-2 gap-2 flex flex-wrap'>
                  {unit.children.map((item, index) => {
                    return (
                      <span
                        className={classNames(
                          'flex align-middle flex-shrink-0 px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full',
                          item.deleteChild ? 'line-through' : '',
                        )}
                      >
                        {item.title}{' '}
                        <TrashIcon
                          className='w-4 h-4 ml-1 text-gray-700 hover:text-gray-500 hover:cursor-pointer'
                          aria-hidden='true'
                          onClick={() => {
                            toggleDeleteChild(index);
                          }}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className='px-4 sm:px-6 sm:py-5'>
            <div>
              <label
                htmlFor='project-name'
                className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
              >
                {' '}
                Prijzen Laagseizoen: *{' '}
              </label>
            </div>
            <div className='mt-2'>
              {laagSeizoen.length === 0 && (
                <span className='mt-2 pl-2'>
                  Er zijn geen onderliggende items toegevoegd.
                </span>
              )}
              {laagSeizoen.length > 0 &&
                laagSeizoen.map(item => {
                  return (
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-red-100 rounded-lg hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75 mb-2'>
                            <span>
                              {item.period && item.period.name}
                              {' (€'}
                              {item.price})
                            </span>
                            <ChevronUpIcon
                              className={`${
                                open ? 'transform rotate-180' : ''
                              } w-5 h-5 text-purple-500`}
                            />
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            enter='transition duration-100 ease-out'
                            enterFrom='transform scale-95 opacity-0'
                            enterTo='transform scale-100 opacity-100'
                            leave='transition duration-75 ease-out'
                            leaveFrom='transform scale-100 opacity-100'
                            leaveTo='transform scale-95 opacity-0'
                          >
                            <Disclosure.Panel
                              className='text-sm text-gray-500'
                              static
                            >
                              <div className='grid grid-cols-4 gap-2 w-full px-4 py-4 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-lg mb-2'>
                                <div className='col-span-2 space-y-2'>
                                  <label htmlFor='minPersons'>Periode</label>
                                  <Combobox
                                    items={
                                      periodData && periodData.findManyPeriods
                                        ? parsePeriodOptions(
                                            periodData.findManyPeriods,
                                          )
                                        : []
                                    }
                                    selected={
                                      item.period
                                        ? {
                                            id: item.period.id!,
                                            name: item.period.name,
                                          }
                                        : undefined
                                    }
                                    setSelected={value => {
                                      updateUnitPeriod(
                                        'period',
                                        value.id,
                                        item.id,
                                      );
                                    }}
                                  />
                                </div>
                                <div className='col-span-2 space-y-2'>
                                  <label htmlFor='minPersons'>Prijs</label>
                                  <Input
                                    name='price'
                                    id='price'
                                    type='number'
                                    value={item && item.price ? item.price : ''}
                                    onChange={e => {
                                      const { target } = e;
                                      const { value } = target;
                                      updateUnitPeriod('price', value, item.id);
                                    }}
                                  />
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  );
                })}
            </div>
            <div className='text-right'>
              <Button
                primary
                size='sm'
                onClick={e => {
                  e.preventDefault();
                  addUnitPeriod(1);
                }}
              >
                Voeg periode toe
              </Button>
            </div>
          </div>
          <div className='px-4 sm:px-6 sm:py-5'>
            <div>
              <label
                htmlFor='project-name'
                className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
              >
                {' '}
                Prijzen Hoogseizoen: *{' '}
              </label>
            </div>
            <div className='mt-2'>
              {hoogSeizoen.length === 0 && (
                <span className='mt-2 pl-2'>
                  Er zijn geen onderliggende items toegevoegd.
                </span>
              )}
              {hoogSeizoen.length > 0 &&
                hoogSeizoen.map(item => {
                  return (
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-red-100 rounded-lg hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75 mb-2'>
                            <span>
                              {item.period && item.period.name}
                              {' (€'}
                              {item.price})
                            </span>
                            <ChevronUpIcon
                              className={`${
                                open ? 'transform rotate-180' : ''
                              } w-5 h-5 text-purple-500`}
                            />
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            enter='transition duration-100 ease-out'
                            enterFrom='transform scale-95 opacity-0'
                            enterTo='transform scale-100 opacity-100'
                            leave='transition duration-75 ease-out'
                            leaveFrom='transform scale-100 opacity-100'
                            leaveTo='transform scale-95 opacity-0'
                          >
                            <Disclosure.Panel
                              className='text-sm text-gray-500'
                              static
                            >
                              <div className='grid grid-cols-4 gap-2 w-full px-4 py-4 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-lg mb-2'>
                                <div className='col-span-2 space-y-2'>
                                  <label htmlFor='minPersons'>Periode</label>
                                  <Combobox
                                    items={
                                      periodData && periodData.findManyPeriods
                                        ? parsePeriodOptions(
                                            periodData.findManyPeriods,
                                          )
                                        : []
                                    }
                                    selected={
                                      item.period
                                        ? {
                                            id: item.period.id!,
                                            name: item.period.name,
                                          }
                                        : undefined
                                    }
                                    setSelected={value => {
                                      updateUnitPeriod(
                                        'period',
                                        value.id,
                                        item.id,
                                      );
                                    }}
                                  />
                                </div>
                                <div className='col-span-2 space-y-2'>
                                  <label htmlFor='minPersons'>Prijs</label>
                                  <Input
                                    name='price'
                                    id='price'
                                    type='number'
                                    value={item && item.price ? item.price : ''}
                                    onChange={e => {
                                      const { target } = e;
                                      const { value } = target;
                                      updateUnitPeriod('price', value, item.id);
                                    }}
                                  />
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  );
                })}
            </div>
            <div className='text-right'>
              <Button
                primary
                size='sm'
                onClick={e => {
                  e.preventDefault();
                  addUnitPeriod(2);
                }}
              >
                Voeg periode toe
              </Button>
            </div>
          </div>
          <div className='px-4 sm:px-6 sm:py-5'>
            <div>
              <label
                htmlFor='project-name'
                className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
              >
                {' '}
                Synchronisatie naar andere platformen:{' '}
              </label>
            </div>
            <div className='flex flex-col text-md mt-2'>
              <span className=''>
                Gebruik deze url voor synchronisatie van eigen boekingen naar
                een extern systeem:
              </span>
              <a
                href={`${process.env.REACT_APP_BACKEND_URI}/api/ical/${unit.id}`}
                target='_blank'
                rel='noreferrer'
                className='text-red-400 hover:text-red-600 hover:cursor-pointer'
              >{`${process.env.REACT_APP_BACKEND_URI}/api/ical/${unit.id}`}</a>
            </div>
            <div className='mt-2'>
              {unit &&
                unit.externalSources &&
                unit.externalSources.length === 0 && (
                  <span>Geen externe bronnen ingesteld.</span>
                )}
            </div>
          </div>
          <div className='px-4 sm:px-6 sm:py-5'>
            <div>
              <label
                htmlFor='project-name'
                className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
              >
                {' '}
                Synchronisatie van andere platformen naar eigen website:{' '}
              </label>
            </div>
            <div className='mt-2'>
              {unit &&
                unit.externalSources &&
                unit.externalSources.length === 0 && (
                  <span>Geen externe bronnen ingesteld.</span>
                )}
              {unit &&
                unit.externalSources &&
                unit.externalSources.length > 0 &&
                unit.externalSources.map((source, key) => {
                  return (
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-red-100 rounded-lg hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75 mb-2'>
                            <span>{source.title}</span>
                            <ChevronUpIcon
                              className={`${
                                open ? 'transform rotate-180' : ''
                              } w-5 h-5 text-purple-500`}
                            />
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            enter='transition duration-100 ease-out'
                            enterFrom='transform scale-95 opacity-0'
                            enterTo='transform scale-100 opacity-100'
                            leave='transition duration-75 ease-out'
                            leaveFrom='transform scale-100 opacity-100'
                            leaveTo='transform scale-95 opacity-0'
                          >
                            <Disclosure.Panel
                              className='text-sm text-gray-500'
                              static
                            >
                              <div className='grid grid-cols-7 gap-2 w-full px-4 py-4 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-lg mb-2'>
                                <div className='col-span-3 space-y-2'>
                                  <div className='w-full'>
                                    <label htmlFor='sourceTitle'>naam:</label>
                                    <Input
                                      name='sourceTitle'
                                      type='text'
                                      value={
                                        source && source.title
                                          ? source.title
                                          : ''
                                      }
                                      onChange={e =>
                                        onChangeSourceTitle(e, key)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='col-span-4 space-y-2'>
                                  <div className='w-full'>
                                    <label htmlFor='sourceUrl'>url: *</label>
                                    <div className='flex'>
                                      <Input
                                        name='sourceUrl'
                                        type='text'
                                        value={
                                          source && source.url ? source.url : ''
                                        }
                                        onChange={e =>
                                          onChangeSourceURL(e, key)
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          deleteExternalSource(key)
                                        }
                                        className='hover:cursor-pointer text-red-300 hover:text-red-200 ml-2 mr-2 text-lg'
                                        type='button'
                                      >
                                        {source.delete ? (
                                          <FontAwesomeIcon
                                            icon={faTrashRestore}
                                          />
                                        ) : (
                                          <FontAwesomeIcon icon={faTrash} />
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  );
                })}
            </div>
            <div className='mt-2'>
              <Button
                onClick={addExternalSource}
                primary
                type='button'
                size='md'
              >
                Toevoegen
              </Button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

export const Units: React.FC = ({ ...props }) => {
  const [isOpen, setOpen] = useState(false);

  const [error, setError] = useState<any>();
  // const [units, setUnits] = useState<IUnit[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [selection, setSelection] = useState<IUnit | undefined>();
  const [sorting, setSorting] = useState<
    { key: string; direction: string } | undefined
  >({ key: 'title', direction: 'asc' });
  const [debouncedFilter, setDebouncedFilter] = useState<string>('');

  useDebounce(filter, 500, (value: any) => {
    setDebouncedFilter(value);
  });
  const {
    data: units,
    loading,
    refetch,
  } = useQuery(GET_UNITS, {
    variables: {
      query: {
        where: {
          OR: [{ title: { contains: debouncedFilter } }],
        },
        orderBy: sorting ? { [sorting.key]: sorting.direction } : undefined,
      },
    },
  });

  const editItem = (item: IUnit) => {
    setSelection(item);
    setOpen(true);
  };

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column?: IColumn,
  ): void => {
    if (column && column.fieldName) {
      setSorting({
        key: column.fieldName,
        direction: column.isSortedDescending ? 'asc' : 'desc',
      });
    }
  };

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      render: (item: IUnit) => item.title,
      fieldName: 'title',
      onHeaderClick: onColumnClick,
      sortable: true,
      isSorted: sorting && sorting.key === 'title',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column2',
      name: 'Min p.',
      fieldName: 'minPersons',
      render: (item: IUnit) => item.minPersons,
      onHeaderClick: onColumnClick,
      sortable: true,
      isSorted: sorting && sorting.key === 'minPersons',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column3',
      name: 'Max p.',
      fieldName: 'maxPersons',
      render: (item: IUnit) => item.maxPersons,
      isSorted: sorting && sorting.key === 'maxPersons',
      isSortedDescending: sorting && sorting.direction === 'desc',
      sortable: true,
      onHeaderClick: onColumnClick,
    },
    {
      key: 'column4',
      name: 'Samengesteld uit',
      render: (item: IUnit) => {
        if (item.children && item.children.length > 0) {
          return (
            <span>
              {item.children.map(child => {
                return (
                  <span
                    className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'
                    key={child.id}
                  >
                    {child.title}
                  </span>
                );
              })}
            </span>
          );
        }
        return <span />;
      },
    },
    {
      key: 'column5',
      name: 'id',
      fieldName: 'id',
      render: (item: IUnit) => item.id,
    },
    {
      key: 'column6',
      name: 'waarborg',
      fieldName: 'guarantee',
      render: (item: IUnit) => `€ ${item.guaranteeAmount}`,
    },
    {
      key: 'column7',
      name: 'voorschot',
      fieldName: 'deposit',
      render: (item: IUnit) => `€ ${item.depositAmount}`,
    },
    {
      key: 'column8',
      name: '',
      render: (item: IUnit) => {
        return (
          <button
            className='text-indigo-600 hover:text-indigo-900'
            type='button'
            onClick={() => editItem(item)}
          >
            bewerken<span className='sr-only'>, {item.title}</span>
          </button>
        );
      },
    },
  ];

  const [createUnit] = useMutation(CREATE_UNIT, {
    refetchQueries: [{ query: GET_UNITS }],
  });

  const [updateUnit] = useMutation(UPDATE_UNIT, {
    refetchQueries: [{ query: GET_UNITS }],
  });

  const [deleteUnit] = useMutation(DELETE_UNIT, {
    refetchQueries: [{ query: GET_UNITS }],
  });

  const save = React.useCallback(() => {
    if (selection) {
      const connectArray: any[] = [];
      const deleteArray: any[] = [];

      if (selection.children) {
        selection.children.forEach(item => {
          if (item.new && !item.deleteChild && item.id) {
            connectArray.push({
              id: item.id,
            });
          }
        });

        selection.children.forEach(item => {
          if (item.deleteChild) {
            deleteArray.push({
              id: item.id,
            });
          }
        });
      }

      const unitPeriodCreateArray: any[] = [];
      const unitPeriodUpdateArray: any[] = [];
      const unitPeriodDeleteArray: any[] = [];

      if (selection.unitPeriods && selection.unitPeriods.length > 0) {
        selection.unitPeriods.forEach(item => {
          if (item.new && item.period && !item.delete && item.period.id) {
            unitPeriodCreateArray.push({
              season: { connect: { id: item.season.id } },
              period: { connect: { id: item.period.id } },
              price: Number(item.price),
            });
          } else if (
            item.dirty &&
            item.period &&
            !item.delete &&
            item.period.id
          ) {
            unitPeriodUpdateArray.push({
              where: { id: item.id },
              data: {
                season: { connect: { id: item.season.id } },
                period: { connect: { id: item.period.id } },
                price: Number(item.price),
              },
            });
          } else if (item.delete) {
            unitPeriodDeleteArray.push({
              id: item.id,
            });
          }
        });
      }

      const externalSourcesCreate: any[] = [];
      const externalSourcesDelete: any[] = [];
      const externalSourcesUpdate: any[] = [];

      if (selection.externalSources && selection.externalSources.length > 0) {
        selection.externalSources.forEach(item => {
          if (item.new && !item.delete) {
            externalSourcesCreate.push({
              url: item.url,
              title: item.title,
            });
          } else if (!item.new && item.dirty && !item.delete) {
            externalSourcesUpdate.push({
              where: { id: item.id },
              data: {
                url: item.url,
                title: item.title,
              },
            });
          } else if (item.delete && !item.new) {
            externalSourcesDelete.push({ id: item.id });
          }
        });
      }

      if (selection.id) {
        updateUnit({
          variables: {
            data: {
              title: selection.title,
              body: selection.body,
              maxPersons: selection.maxPersons
                ? Number(selection.maxPersons)
                : undefined,
              minPersons: selection.minPersons
                ? Number(selection.minPersons)
                : undefined,
              guaranteeAmount: selection.guaranteeAmount
                ? Number(selection.guaranteeAmount)
                : undefined,
              depositAmount: selection.depositAmount
                ? Number(selection.depositAmount)
                : undefined,
              children:
                selection.children && selection.children.length > 0
                  ? { connect: connectArray, disconnect: deleteArray }
                  : undefined,
              externalSources: {
                create:
                  externalSourcesCreate.length > 0
                    ? externalSourcesCreate
                    : undefined,
                update:
                  externalSourcesUpdate.length > 0
                    ? externalSourcesUpdate
                    : undefined,
                delete:
                  externalSourcesDelete.length > 0
                    ? externalSourcesDelete
                    : undefined,
              },
              unitPeriods: {
                create:
                  unitPeriodCreateArray.length > 0
                    ? unitPeriodCreateArray
                    : undefined,
                update:
                  unitPeriodUpdateArray.length > 0
                    ? unitPeriodUpdateArray
                    : undefined,
                delete:
                  unitPeriodDeleteArray.length > 0
                    ? unitPeriodDeleteArray
                    : undefined,
              },
            },
            where: {
              id: selection.id,
            },
          },
        })
          .then(d => {
            refetch();
          })
          .catch(err => {
            setError(err);
          });
      } else {
        createUnit({
          variables: {
            data: {
              title: selection.title,
              body: selection.body,
              maxPersons: selection.maxPersons
                ? Number(selection.maxPersons)
                : undefined,
              minPersons: selection.minPersons
                ? Number(selection.minPersons)
                : undefined,
              guaranteeAmount: selection.guaranteeAmount
                ? Number(selection.guaranteeAmount)
                : undefined,
              children:
                selection.children && selection.children.length > 0
                  ? { connect: connectArray }
                  : undefined,
              unitPeriods: {
                create: unitPeriodCreateArray,
                // update: unitPeriodUpdateArray,
                // disconnect: unitPeriodDeleteArray,
              },
            },
          },
        })
          .then(d => {
            refetch();
          })
          .catch(err => {
            setError(err);
          });
      }
    }

    setOpen(false);
    setSelection(undefined);
  }, [selection, setOpen, createUnit, updateUnit, refetch]);

  /* const confirmDeleteItem = () => {
    if (selection) {
      deleteUnit({
        variables: {
          where: {
            id: selection.id,
          },
        },
      })
        .then(d => {
          refetch();
          closeDialog();
        })
        .catch(err => {
          setError(err);
          closeDialog();
        });
    }
  }; */

  const formComplete = React.useCallback((unit: IUnit | undefined) => {
    if (!unit) return false;
    let valid = true;
    if (!unit.title || unit.title.length < 2) valid = false;
    if (!unit.maxPersons || unit.maxPersons < 1) valid = false;
    if (!unit.minPersons || unit.minPersons < 1) valid = false;

    return valid;
  }, []);

  const onRenderFooterContent = React.useCallback(
    () => (
      <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
        <div className='flex justify-end space-x-3'>
          <Button
            secondary
            size='lg'
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            size='lg'
            onClick={() => {
              save();
            }}
            className='mr-1'
            disabled={!formComplete(selection)}
          >
            Save
          </Button>
        </div>
      </div>
    ),
    [setOpen, formComplete, selection, save],
  );

  const navigation = [
    {
      name: 'Nieuw',
      onClick: () => {
        setSelection(undefined);
        setOpen(true);
      },
      current: false,
      // disabled: !!(selection && selection.id),
    },
    /* {
      name: 'Bewerken',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faPen} />,
      disabled: !selection || !selection.id,
    },
    {
      name: 'Verwijderen',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faTrash} />,
      disabled: !selection || !selection.id,
    }, */
  ];

  const pages: INavItem[] = [
    { name: 'Dashboard', href: '/' },
    { name: 'Units', href: '/units' },
  ];

  return (
    <div {...props}>
      <PageHeader
        title='Units beheren'
        pages={pages}
        home={{ href: '/' }}
        loading={loading}
      >
        <div className='flex align-middle'>
          {navigation.map((item, index) => {
            return (
              <Button
                size='lg'
                primary
                onClick={item.onClick}
                // disabled={item.disabled}
                className={index > 0 ? 'ml-2' : ''}
              >
                {item.name}
              </Button>
            );
          })}
          <div className='relative ml-5'>
            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
              <SearchIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              id='search'
              name='search'
              className='block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-redo-500 focus:border-redo-500 sm:text-sm'
              placeholder='Search'
              type='search'
              autoComplete='off'
              onChange={e => {
                const { target } = e;
                const { value } = target;
                setFilter(value);
              }}
            />
          </div>
        </div>
      </PageHeader>
      <Notification
        show={!!error}
        dismiss={() => {
          setError(undefined);
        }}
        level='error'
        title='Er ging iets mis'
      >
        {error?.message}
      </Notification>
      <Table items={units ? units.findManyUnits : []} columns={columns} />

      <Slideover
        title={selection && selection.id ? 'Unit bewerken' : 'Unit toevoegen'}
        description='Voeg een nieuwe unit toe of bewerk een bestaande unit.'
        open={isOpen}
        setOpen={setOpen}
        actions={onRenderFooterContent()}
        lightDismiss
      >
        <CreateUpdateForm handleChange={setSelection} unit={selection} />
      </Slideover>
    </div>
  );
};

export default Units;

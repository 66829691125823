import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { useQuery, useLazyQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { GET_UNITS } from '../graphql/unit';
import { GET_AVAILABILITY, GET_BOOKING_INFO } from '../graphql/availability';
import { IUnit } from '../types';
import Calender from '../components/calender';
import { PageHeader } from '../components/pageheader';
import { INavItem } from '../components/navbar';

const CalenderObject: React.FC<{ unit?: number; currentMonth: Moment }> = ({
  unit,
  currentMonth,
  ...props
}) => {
  const { data: dataCalender, loading: loadingCalender } = useQuery(
    GET_AVAILABILITY,
    {
      variables: {
        where: {
          start: moment(currentMonth).startOf('month').format(),
          end: moment(currentMonth).endOf('month').format(),
          // period: booking && booking.period ? booking.period.id : 1,
          // persons: booking && booking.persons ? booking.persons : 1,
          unit,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  return (
    <div>
      <Calender
        // currentSelection={currentDateSelection}
        setSelection={date => ({})}
        currentSelectionDuration={0}
        month={currentMonth.month() + 1}
        year={currentMonth.year()}
        dateValidation={
          dataCalender && dataCalender.getAvailability
            ? dataCalender.getAvailability
            : []
        }
        loading={loadingCalender}
        hideSelection
      />
    </div>
  );
};

export const Dashboard: React.FC = ({ ...props }) => {
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment());

  const { data: dataUnits, loading: loadingUnits } = useQuery(GET_UNITS, {
    variables: {
      query: {
        where: {
          NOT: {
            dummy: { equals: true },
          },

          // ...getSelectedUnitFilter(),
        },
      },
    },
  });

  const pages: INavItem[] = [{ name: 'Dashboard', href: '/' }];

  return (
    <div {...props}>
      <PageHeader
        title='Overzicht boekingen'
        pages={pages}
        home={{ href: '/' }}
      />
      <div className='flex items-center text-center text-gray-900 max-w-md mx-auto mb-4'>
        <button
          type='button'
          onClick={() => {
            setCurrentMonth(moment(currentMonth).subtract(1, 'month'));
          }}
          className='-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
        >
          <span className='sr-only'>Previous month</span>
          <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
        </button>
        <div className='flex-auto font-semibold'>
          {currentMonth.format('MMMM YYYY')}
        </div>
        <button
          type='button'
          onClick={() => {
            setCurrentMonth(moment(currentMonth).add(1, 'month'));
          }}
          className='-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
        >
          <span className='sr-only'>Next month</span>
          <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
        </button>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {dataUnits &&
          dataUnits.findManyUnits &&
          dataUnits.findManyUnits.map((item: IUnit, index: number) => {
            return (
              <div className='w-1/2 px-3 mb-3 text-center pb-10'>
                <h2>{item.title}</h2>
                <CalenderObject unit={item.id} currentMonth={currentMonth} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Dashboard;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import Logo from '../graphic/logo';
import Logo from '../graphic/logo';
import { Navbar, INavItem } from './navbar';

const defaultStyle = {
  background: 'muted',
  py: 3,
};
/*
const Logo = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.0'
    id='Layer_1'
    x='0px'
    y='0px'
    // width='300px'
    height='40px'
    viewBox='0 0 300 94'
    enableBackground='new 0 0 300 94'
  >
    <g>
      <g>
        <g>
          <g>
            <path d='M13.4,93.5c-2,0-4-0.3-6.1-1C5.5,93,4,93.2,2.7,93.2c-1.3,0-2.2,0-2.7-0.1c0.2-2,1.3-7.1,3.1-15.2      C4.9,69.7,5.8,65.1,5.8,64c0-1.1-0.2-2.1-0.7-2.9c-0.5-0.8-0.9-1.4-1.3-1.8l-0.7-0.6l0.2-0.8c0.9-0.2,2.7-0.5,5.4-0.8      c2.7-0.3,5.1-0.4,7.1-0.4c4.7,0,8.3,1,10.7,3.1c2.4,2.1,3.6,5.5,3.6,10.1c0,3.8-0.6,7.5-1.9,11c-1.3,3.5-3.2,6.5-5.8,8.8      C19.7,92.3,16.7,93.5,13.4,93.5z M17,60c-0.9,0-1.9,0.1-3,0.3c-0.3,3.6-1.3,8.8-2.9,15.7C9.5,83,8.6,87.4,8.3,89.2      c0.8,0.4,1.9,0.6,3.4,0.6c1.4,0,2.8-0.6,4-1.7c1.3-1.1,2.3-2.5,3.1-4.2c0.8-1.7,1.5-3.6,2-5.7c1-3.8,1.5-7.2,1.5-10.3      c0-3.1-0.4-5.2-1.2-6.3C20.3,60.6,18.9,60,17,60z' />
            <path d='M32.9,93.1h-0.4c-0.1-0.3-0.1-0.9-0.1-1.9c0-1,0.5-3.6,1.4-7.8c0.9-4.2,1.4-6.7,1.4-7.3c0-1-0.5-2.2-1.4-3.5l-0.5-0.6      l0-0.7c1.8-0.5,4.8-0.8,8.8-0.8c0.4,0.9,0.6,2.1,0.6,3.7c0.4-0.6,1.2-1.4,2.3-2.4c1.2-1,2.4-1.5,3.7-1.5c1.8,0,2.7,1.6,2.7,4.8      c-0.2,0.2-0.4,0.5-0.7,0.8c-0.3,0.3-0.9,0.8-1.7,1.3c-0.9,0.6-1.7,0.9-2.5,1.1c0,0-0.3-0.5-0.9-1.4c-0.5-0.9-0.9-1.4-1.2-1.4      c-0.8,0.3-1.6,0.8-2.2,1.5c-1.6,7-2.3,11.5-2.3,13.6c0,0.7,0,1.3,0.1,1.6C38.7,92.8,36.4,93.1,32.9,93.1z' />
            <path d='M60.9,73.5c0,1.4-0.5,3.9-1.4,7.3c-0.9,3.5-1.4,5.7-1.4,6.8c0,1.1,0.2,1.6,0.5,1.6c0.2,0,1-0.3,2.3-1l0.6-0.3l0.9,1.8      c-0.3,0.3-0.7,0.6-1.2,1c-0.5,0.4-1.5,1-2.8,1.7c-1.4,0.7-2.7,1-3.9,1c-1.2,0-2.1-0.4-2.8-1.1c-0.7-0.7-1-1.7-1-2.9      c0-1.2,0.4-3.4,1.3-6.6c0.8-3.2,1.3-5.2,1.3-6.2c0-1.4-0.4-2.8-1.3-4l-0.5-0.6l0.1-0.7c1.8-0.5,4.7-0.8,8.7-0.8      C60.6,71.2,60.9,72.1,60.9,73.5z M55.1,65.1c-0.7-0.7-1-1.6-1-2.7c0-1.1,0.5-2.1,1.4-2.9c0.9-0.8,2-1.3,3.2-1.3      c1.2,0,2.2,0.3,2.8,1c0.7,0.7,1,1.6,1,2.7c0,1.1-0.5,2.1-1.4,2.9c-1,0.8-2,1.3-3.2,1.3C56.7,66.1,55.8,65.8,55.1,65.1z' />
            <path d='M64,84.1c0-4.2,1.3-7.6,3.8-10c2.6-2.5,5.5-3.7,8.7-3.7c2,0,3.6,0.5,4.9,1.5c1.3,1,1.9,2.3,1.9,3.9c0,1.6-0.4,3-1.2,4.1      c-0.8,1.1-1.8,2-3,2.6c-2.4,1.2-4.6,1.9-6.6,2.2l-1.2,0.2c0.2,3.2,1.5,4.8,3.9,4.8c0.8,0,1.7-0.2,2.6-0.6      c0.9-0.4,1.6-0.8,2.1-1.2l0.8-0.6l1.2,1.6c-0.3,0.4-0.8,0.9-1.6,1.5c-0.8,0.6-1.6,1.1-2.3,1.5c-2,1.1-4.1,1.6-6.5,1.6      c-2.3,0-4.2-0.8-5.5-2.5C64.6,89.4,64,87.1,64,84.1z M75.3,80.3c1-1.2,1.5-2.6,1.5-4.5c0-1.8-0.5-2.7-1.6-2.7      c-1.3,0-2.3,1.1-3,3.3c-0.7,2.2-1,4.2-1,6.1C73,82.1,74.3,81.4,75.3,80.3z' />
            <path d='M90.4,59.4c0-1.1-0.1-1.9-0.3-2.4c2.6-0.9,5.1-1.4,7.5-1.4c0.3,0.3,0.5,1.1,0.5,2.3c0,2.2-0.9,7.4-2.8,15.4      c2.7-2,5.1-3,7.2-3c1.3,0,2.3,0.4,2.9,1.2c0.7,0.8,1,1.9,1,3.2c0,1.3-0.4,3.5-1.2,6.6c-0.8,3-1.2,5.1-1.2,6.2      c0,1.1,0.1,1.7,0.4,1.7c0.3,0,1-0.3,2.1-0.9l0.6-0.3l0.9,1.8c-0.3,0.3-0.7,0.6-1.2,1c-0.5,0.4-1.4,0.9-2.8,1.6      c-1.4,0.7-2.6,1-3.7,1c-1.1,0-2-0.4-2.7-1.1c-0.7-0.7-1-1.7-1-2.9c0-1.2,0.4-3.5,1.1-6.8c0.8-3.3,1.1-5.3,1.1-6.2      c0-0.9-0.4-1.3-1.1-1.3c-0.9,0-2,0.4-3.3,1.1c-1.9,8.2-2.8,13.5-2.8,16c-1.4,0.5-3.8,0.8-7.3,0.8c0-0.6-0.1-1.1-0.1-1.5      c0-1.5,1-6.4,3-14.9C89.4,68.1,90.4,62.4,90.4,59.4z' />
            <path d='M116.7,93.5c-2.3,0-4.2-0.8-5.5-2.5c-1.3-1.6-2-3.9-2-6.9c0-3.8,1-7,3-9.7c2-2.7,4.8-4.1,8.4-4.1l1.8,0.1      c0.6-0.3,1.2-0.5,1.9-0.5c2.7,0,4,2.5,4,7.6c1.4-0.1,2.8-0.3,4.2-0.6l0.8-0.2l0.3,2c-1.1,0.5-2.6,0.9-4.5,1.3l-1,0.2      c-0.6,3.6-1.9,6.7-3.9,9.3C122.4,92.2,119.8,93.5,116.7,93.5z M119.5,89.1c1.4,0,2.7-0.9,3.8-2.7c1.1-1.8,1.9-3.8,2.4-5.8      c-3.6,0-5.4-1.4-5.4-4.1c0-1.1,0.2-2.1,0.7-3l0.3-0.5c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.5,0.1c-0.8,0.5-1.6,1.8-2.4,3.8      c-0.7,2-1.1,4.3-1.1,6.9C116.7,87.3,117.6,89.1,119.5,89.1z' />
            <path d='M130.2,84.1c0-4.2,1.3-7.6,3.8-10c2.6-2.5,5.5-3.7,8.7-3.7c2,0,3.6,0.5,4.9,1.5c1.3,1,1.9,2.3,1.9,3.9      c0,1.6-0.4,3-1.2,4.1c-0.8,1.1-1.8,2-3,2.6c-2.4,1.2-4.6,1.9-6.6,2.2l-1.2,0.2c0.2,3.2,1.5,4.8,3.9,4.8c0.8,0,1.7-0.2,2.6-0.6      c0.9-0.4,1.6-0.8,2.1-1.2l0.8-0.6l1.2,1.6c-0.3,0.4-0.8,0.9-1.6,1.5c-0.8,0.6-1.6,1.1-2.3,1.5c-2,1.1-4.1,1.6-6.5,1.6      c-2.3,0-4.2-0.8-5.5-2.5C130.9,89.4,130.2,87.1,130.2,84.1z M141.6,80.3c1-1.2,1.5-2.6,1.5-4.5c0-1.8-0.5-2.7-1.6-2.7      c-1.3,0-2.3,1.1-3,3.3c-0.7,2.2-1,4.2-1,6.1C139.2,82.1,140.6,81.4,141.6,80.3z' />
            <path d='M156.4,59.4c0-1.1-0.1-1.9-0.3-2.4c2.6-0.9,5.1-1.4,7.5-1.4c0.3,0.3,0.5,1.1,0.5,2.3c0,2.8-0.9,8.2-2.8,16.2      c3-2.5,6-3.7,8.9-3.7c1.3,0,2.4,0.4,3.4,1.2c0.9,0.8,1.4,2,1.4,3.6c0,2.9-2.4,5-7.2,6.4c1.1,3.5,2.1,5.9,2.9,7.2      c0.4,0.5,0.6,0.8,0.8,0.8c0.4,0,1.2-0.3,2.3-0.9l0.6-0.3l0.8,1.7c-0.8,0.7-1.9,1.5-3.4,2.3c-1.4,0.8-2.8,1.1-3.9,1.1      c-2.6,0-4.5-2.4-5.5-7.2c-0.5-2.1-0.8-4-0.9-5.8c2.2-0.5,3.8-1.1,4.9-1.9c1.1-0.7,1.6-1.7,1.6-2.8c0-1.1-0.6-1.7-1.7-1.7      c-1.2,0-3.1,1.2-5.8,3.5c-1.7,7.1-2.5,12-2.5,14.7c-1.9,0.5-4.3,0.8-7.3,0.8c0-0.6,0-1.1,0-1.5c0-1.5,1-6.4,2.9-14.9      C155.5,68.1,156.4,62.4,156.4,59.4z' />
            <path d='M192.2,86.3c0,2.1-0.9,3.9-2.8,5.2c-1.9,1.4-4,2-6.4,2c-2.4,0-4.3-0.5-5.7-1.5c-1.4-1-2.1-1.9-2.1-2.7      c0-0.5,0.6-1.2,1.8-2.3c1.2-1.1,2.2-1.7,3-2c1.7,1.3,3.1,3.2,4.2,5.9c1.3-0.1,1.9-0.7,1.9-1.8c0-1.6-1.4-3.8-4.1-6.6      c-2.7-2.9-4.1-5.1-4.1-6.8c0-1.7,0.8-3,2.3-3.9c1.5-0.9,3.4-1.4,5.5-1.4c2.2,0,3.8,0.4,4.9,1.1c1.1,0.8,1.6,1.8,1.6,3.1      c0,1.3-1,3.1-3.1,5.4c0.2,0.2,0.5,0.6,0.9,0.9c0.4,0.4,0.9,1.1,1.5,2.2C191.9,84.3,192.2,85.3,192.2,86.3z M189.4,74.7      c0-1.2-0.8-1.8-2.4-1.8c-0.8,0-1.4,0.2-1.9,0.5c-0.5,0.3-0.8,0.7-0.8,1.1c0,0.7,0.7,1.8,2.2,3.1l0.8,0.7      C188.7,77.1,189.4,75.9,189.4,74.7z' />
            <path d='M200.5,59.4c0-1.1-0.1-1.9-0.3-2.4c2.6-0.9,5.1-1.4,7.5-1.4c0.3,0.3,0.5,1.1,0.5,2.3c0,2.2-0.9,7.4-2.8,15.4      c2.7-2,5.1-3,7.2-3c1.3,0,2.3,0.4,2.9,1.2c0.7,0.8,1,1.9,1,3.2c0,1.3-0.4,3.5-1.2,6.6c-0.8,3-1.2,5.1-1.2,6.2      c0,1.1,0.1,1.7,0.4,1.7c0.3,0,1-0.3,2.1-0.9l0.6-0.3l0.9,1.8c-0.3,0.3-0.7,0.6-1.2,1c-0.5,0.4-1.4,0.9-2.8,1.6      c-1.4,0.7-2.6,1-3.7,1c-1.1,0-2-0.4-2.7-1.1c-0.7-0.7-1-1.7-1-2.9c0-1.2,0.4-3.5,1.1-6.8c0.8-3.3,1.1-5.3,1.1-6.2      c0-0.9-0.4-1.3-1.1-1.3c-0.9,0-2,0.4-3.3,1.1c-1.9,8.2-2.8,13.5-2.8,16c-1.4,0.5-3.8,0.8-7.3,0.8c0-0.6,0-1.1,0-1.5      c0-1.5,1-6.4,3-14.9C199.5,68.1,200.5,62.4,200.5,59.4z' />
            <path d='M226.8,93.5c-2.3,0-4.2-0.8-5.5-2.5c-1.3-1.6-2-3.9-2-6.9c0-3.8,1-7,3-9.7c2-2.7,4.8-4.1,8.4-4.1l1.8,0.1      c0.6-0.3,1.2-0.5,1.9-0.5c2.7,0,4,2.5,4,7.6c1.4-0.1,2.8-0.3,4.2-0.6l0.8-0.2l0.3,2c-1.1,0.5-2.6,0.9-4.5,1.3l-1,0.2      c-0.6,3.6-1.9,6.7-3.9,9.3C232.4,92.2,229.9,93.5,226.8,93.5z M229.6,89.1c1.4,0,2.7-0.9,3.8-2.7c1.1-1.8,1.9-3.8,2.4-5.8      c-3.6,0-5.4-1.4-5.4-4.1c0-1.1,0.2-2.1,0.7-3l0.3-0.5c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.5,0.1c-0.8,0.5-1.6,1.8-2.4,3.8      c-0.7,2-1.1,4.3-1.1,6.9C226.8,87.3,227.7,89.1,229.6,89.1z' />
            <path d='M240.3,84.1c0-4.2,1.3-7.6,3.8-10c2.6-2.5,5.5-3.7,8.7-3.7c2,0,3.6,0.5,4.9,1.5c1.3,1,1.9,2.3,1.9,3.9      c0,1.6-0.4,3-1.2,4.1c-0.8,1.1-1.8,2-3,2.6c-2.4,1.2-4.6,1.9-6.6,2.2l-1.2,0.2c0.2,3.2,1.5,4.8,3.9,4.8c0.8,0,1.7-0.2,2.6-0.6      c0.9-0.4,1.6-0.8,2.1-1.2l0.8-0.6l1.2,1.6c-0.3,0.4-0.8,0.9-1.6,1.5c-0.8,0.6-1.6,1.1-2.3,1.5c-2,1.1-4.1,1.6-6.5,1.6      c-2.3,0-4.2-0.8-5.5-2.5C241,89.4,240.3,87.1,240.3,84.1z M251.7,80.3c1-1.2,1.5-2.6,1.5-4.5c0-1.8-0.5-2.7-1.6-2.7      c-1.3,0-2.3,1.1-3,3.3c-0.7,2.2-1,4.2-1,6.1C249.3,82.1,250.7,81.4,251.7,80.3z' />
            <path d='M265.7,70.4c1.5,0,2.6,0.3,3.3,0.9c0.7,0.6,1.1,1.9,1.1,3.8c0,1-0.3,3.1-0.9,6.2c-0.6,3.1-0.9,5-0.9,5.7      c0,1.4,0.3,2,0.8,2c1.3,0,2.7-1,4.2-2.9c1.5-1.9,2.3-3.5,2.4-4.8l-3.5-3.9c0.1-1.5,0.5-2.8,1.1-4c0.6-1.2,1.1-2,1.6-2.5l0.7-0.7      c1.5,0,2.6,0.4,3.3,1.2c0.7,0.8,1.1,1.7,1.1,2.7c0,2.7-0.7,5.5-2,8.5c-1.3,3-3.1,5.5-5.3,7.6c-2.2,2.1-4.4,3.1-6.7,3.1      c-1.4,0-2.5-0.4-3.3-1.3c-0.8-0.9-1.1-2-1.1-3.5c0-1.4,0.1-3.4,0.3-5.8c0.2-2.4,0.3-4.9,0.3-7.3c0-0.5-0.2-1.2-0.6-1.9      c-0.4-0.7-0.8-1.3-1.2-1.8l0.2-0.6C262.6,70.7,264.3,70.4,265.7,70.4z' />
            <path d='M280.6,84.1c0-4.2,1.3-7.6,3.8-10c2.6-2.5,5.5-3.7,8.7-3.7c2,0,3.6,0.5,4.9,1.5c1.3,1,1.9,2.3,1.9,3.9      c0,1.6-0.4,3-1.2,4.1c-0.8,1.1-1.8,2-3,2.6c-2.4,1.2-4.6,1.9-6.6,2.2l-1.2,0.2c0.2,3.2,1.5,4.8,3.9,4.8c0.8,0,1.7-0.2,2.6-0.6      c0.9-0.4,1.6-0.8,2.1-1.2l0.8-0.6l1.2,1.6c-0.3,0.4-0.8,0.9-1.6,1.5c-0.8,0.6-1.6,1.1-2.3,1.5c-2,1.1-4.1,1.6-6.5,1.6      c-2.3,0-4.2-0.8-5.5-2.5C281.3,89.4,280.6,87.1,280.6,84.1z M292,80.3c1-1.2,1.5-2.6,1.5-4.5c0-1.8-0.5-2.7-1.6-2.7      c-1.3,0-2.3,1.1-3,3.3c-0.7,2.2-1,4.2-1,6.1C289.6,82.1,291,81.4,292,80.3z' />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <rect x='66.6' y='0.4' fill='#B66A27' width='154.5' height='26' />
            <g>
              <path
                fill='#4D6B77'
                d='M252.2,37.4L221,28.2H66.6L47.8,39.4V51h43.9V32.7h16.8V51h6.3V36.6c0,0,2.5-1.3,7.7-1.3       c5.2,0,7.3,1.3,7.3,1.3V51h57.3V34.5c0,0,1-0.4,3.2-0.4c2.1,0,3,0.4,3,0.4V51h58.9V37.4z M141.2,39.5h-5.2v-3.8       c0,0,0.9-0.4,2.7-0.4c1.8,0,2.5,0.4,2.5,0.4V39.5z M156.4,39.5h-5.2v-3.8c0,0,0.9-0.4,2.7-0.4c1.8,0,2.5,0.4,2.5,0.4V39.5z        M177.8,45.7h-6.2V35.1c0,0,1-0.4,3.2-0.4c2.1,0,3,0.4,3,0.4V45.7z M209.9,45.7h-6.2V35.1c0,0,1-0.4,3.2-0.4c2.1,0,3,0.4,3,0.4       V45.7z'
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              fill='#FFFFFF'
              d='M132,18h-0.1c0-0.7,0.3-2.1,0.8-4.2c0.5-2.1,0.7-3.4,0.7-3.8c0-0.2,0-0.3,0-0.4c-0.5,0.3-1,0.7-1.6,1.3      c-0.1-0.1-0.2-0.1-0.3-0.3c-0.1-0.1-0.2-0.3-0.2-0.4l-0.1-0.2l2.2-1.4c0.7-0.1,1.3-0.2,2-0.2c0.1,0.2,0.2,0.5,0.2,0.9      c0,0.4-0.1,1.1-0.3,2c-0.2,1-0.5,2.1-0.7,3.2c-0.2,1.2-0.4,2.2-0.4,3.2C133.7,17.9,133,18,132,18z'
            />
            <path
              fill='#FFFFFF'
              d='M136.6,16.2c0.5,0.6,1,0.9,1.6,0.9c1.2,0,1.9-1.1,2.2-3.2c-0.5,0.3-1,0.4-1.6,0.4      c-0.8,0-1.3-0.2-1.8-0.7c-0.4-0.5-0.6-1.1-0.6-1.8c0-0.9,0.3-1.7,1-2.4c0.7-0.6,1.5-1,2.4-1c0.9,0,1.7,0.3,2.1,0.9      c0.5,0.6,0.7,1.5,0.7,2.6c0,1.2-0.2,2.2-0.5,3.1c-0.3,0.9-0.8,1.6-1.4,2.2c-0.6,0.6-1.4,0.9-2.2,0.9c-0.7,0-1.2-0.2-1.6-0.5      c-0.4-0.3-0.7-0.7-1-1L136.6,16.2z M139.5,13.4c0.4,0,0.7-0.1,1-0.3c0-0.5,0.1-1.1,0.1-1.7c0-0.6-0.1-1.1-0.2-1.5      c-0.1-0.4-0.4-0.6-0.7-0.6c-0.4,0-0.7,0.2-0.9,0.7c-0.2,0.5-0.3,1.1-0.3,1.7C138.5,12.8,138.8,13.4,139.5,13.4z'
            />
            <path
              fill='#FFFFFF'
              d='M147.7,18.2c-0.3,0-0.8-0.2-1.4-0.5c-0.6-0.4-1.1-0.6-1.6-0.7c-0.3,0.3-0.6,0.6-0.9,0.8      c-0.3,0.2-0.5,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.5-0.5c-0.3-0.3-0.4-0.6-0.4-0.7c0-0.2,0.1-0.4,0.4-0.7c0.3-0.3,0.6-0.5,1-0.5      c0.2,0,0.5,0,0.7,0.1c0.6-0.5,1.2-1.2,1.8-2.2c0.6-1,0.8-1.8,0.8-2.5c0-0.4-0.1-0.7-0.4-0.9c-0.2-0.2-0.5-0.3-0.9-0.3      c-0.3,0-0.7,0.1-1,0.3c-0.3,0.2-0.6,0.5-0.8,0.9l-0.6-0.3c0.2-0.6,0.6-1.1,1.2-1.6c0.6-0.5,1.3-0.7,2.1-0.7      c0.8,0,1.4,0.2,1.8,0.6c0.4,0.4,0.7,0.9,0.7,1.6c0,0.5-0.1,1-0.4,1.5c-0.3,0.5-0.6,1.1-1,1.6c-0.8,0.9-1.5,1.7-2.3,2.3      c1.1,0.3,2,0.5,2.6,0.5c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.3,0.4-0.7l0.4,0.1C149.3,17.4,148.7,18.2,147.7,18.2z'
            />
            <path
              fill='#FFFFFF'
              d='M154.3,9.3c-0.5,0-1,0.3-1.3,1c-0.3,0.7-0.6,1.5-0.7,2.6c0.5-0.3,1.2-0.5,1.9-0.5c0.7,0,1.2,0.2,1.6,0.7      c0.4,0.5,0.6,1.1,0.6,1.9c0,0.8-0.3,1.5-1,2.2c-0.6,0.7-1.4,1-2.4,1c-1.9,0-2.8-1.2-2.8-3.5c0-1.1,0.2-2.1,0.5-3.1      c0.4-0.9,0.9-1.7,1.5-2.2c0.7-0.6,1.4-0.8,2.2-0.8c0.6,0,1.1,0.2,1.6,0.5c0.5,0.3,0.7,0.8,0.7,1.3c0,0.4-0.1,0.7-0.3,1      c-0.1,0-0.2,0-0.3,0c-0.7,0-1.1-0.2-1.5-0.5l0.2-1.3C154.7,9.3,154.5,9.3,154.3,9.3z M153.3,13.3c-0.3,0-0.7,0.2-1.1,0.5      c0,0.4-0.1,1-0.1,1.7c0,0.7,0.1,1.2,0.2,1.5c0.1,0.3,0.3,0.4,0.6,0.4c0.4,0,0.7-0.3,0.9-0.8c0.2-0.5,0.3-1.1,0.3-1.7      C154.2,13.8,153.9,13.3,153.3,13.3z'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
*/
const LogoDark = () => <Logo />;

const LogoLight = () => <Logo />;
const Header: React.FC = ({ ...props }) => {
  const {
    logout,
    user,
    // getAccessTokenSilently,
  } = useAuth0();

  // getAccessTokenSilently().then((token) => console.log(token));

  const navItems: INavItem[] = [
    { name: 'Boekingen', href: '/boekingen' },
    { name: 'Units', href: '/units' },
    { name: 'Opties', href: '/opties' },
    { name: 'Periodes', href: '/periodes' },
    { name: 'Seizoenen', href: '/seizoenen' },
  ];

  const userNavigation: INavItem[] = [
    {
      name: 'Afmelden',
      onClick: () => {
        logout();
      },
    },
  ];

  return (
    <div className='mb-10' {...props}>
      <Navbar
        LogoDark={LogoDark}
        LogoLight={LogoLight}
        navigation={navItems}
        userNavigation={userNavigation}
      />
    </div>
  );
};

export default Header;
export { Header };

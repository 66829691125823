/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useQuery, useMutation } from '@apollo/client';
import { SearchIcon, ExclamationIcon } from '@heroicons/react/solid';
import _, { update } from 'lodash';
import moment, { Moment } from 'moment';
import { Button } from '../components/button';
import { Input } from '../components/input';
import { PageHeader } from '../components/pageheader';
import { Table, IColumn } from '../components/table';
import { Slideover } from '../components/slideover';
import { Notification } from '../components/notification';
import { Dialog } from '../components/dialog';
import useDebounce from '../components/hooks/useDebounce';
import { IHighSeasonPeriod, ISeason, IUnit } from '../types';
import {
  GET_HIGHSEASONPERIODS,
  CREATE_HIGHSEASONPERIOD,
  UPDATE_HIGHSEASONPERIOD,
  DELETE_HIGHSEASONPERIOD,
} from '../graphql/highSeasonPeriod';

import 'react-datepicker/dist/react-datepicker.css';
import { INavItem } from '../components/navbar';
import { Switch } from '../components/switch';
import { GET_UNITS } from '../graphql/unit';
import { Checkbox } from '../components/checkbox';

interface ICreateUpdate {
  highSeasonPeriod?: IHighSeasonPeriod;
  units?: IUnit[];
  handleChange?: React.Dispatch<
    React.SetStateAction<IHighSeasonPeriod | undefined>
  >;
}

const CreateUpdateForm: React.FC<ICreateUpdate> = ({
  highSeasonPeriod,
  units,
  handleChange = () => ({}),
}) => {
  const onChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { id, value } = target;

    handleChange(prevState => {
      let updatedHighSeasonPeriod: IHighSeasonPeriod | undefined =
        _.cloneDeep(prevState);

      if (!updatedHighSeasonPeriod) updatedHighSeasonPeriod = {};

      updatedHighSeasonPeriod = {
        ...updatedHighSeasonPeriod,
        [id]: value,
      };
      return updatedHighSeasonPeriod;
    });
  };

  const changeDate = (date: string | Date | Moment, id: string) => {
    handleChange(prevState => {
      let updatedHighSeasonPeriod: IHighSeasonPeriod | undefined =
        _.cloneDeep(prevState);

      if (!updatedHighSeasonPeriod) updatedHighSeasonPeriod = {};

      updatedHighSeasonPeriod = {
        ...updatedHighSeasonPeriod,
        [id]: date ? moment(date).toISOString() : undefined,
      };
      return updatedHighSeasonPeriod;
    });
  };

  const _onChangeInputDiscount = (checked: boolean) => {
    handleChange(prevState => {
      let updatedHighSeasonPeriod: IHighSeasonPeriod | undefined =
        _.cloneDeep(prevState);

      if (!updatedHighSeasonPeriod) updatedHighSeasonPeriod = {};

      updatedHighSeasonPeriod = {
        ...updatedHighSeasonPeriod,
        discount: checked,
      };

      return updatedHighSeasonPeriod;
    });
  };

  const _onChangeUnitSelection = (id: number, checked: boolean) => {
    handleChange(prevState => {
      let updatedHighSeasonPeriod: IHighSeasonPeriod | undefined =
        _.cloneDeep(prevState);

      if (!updatedHighSeasonPeriod) updatedHighSeasonPeriod = {};

      if (!updatedHighSeasonPeriod.units) updatedHighSeasonPeriod.units = [];

      const newUnits = updatedHighSeasonPeriod.units || [];

      if (newUnits.length === 0 && checked) {
        newUnits.push({ id, new: true });
      } else if (checked) {
        let unitExists = false;
        for (let i = 0; i < newUnits.length; i += 1) {
          if (newUnits[i].id === id) {
            unitExists = true;
            newUnits[i].delete = false;
          }
        }

        if (!unitExists) {
          newUnits.push({ id, new: true });
        }
      }

      if (!checked) {
        for (let i = 0; i < newUnits.length; i += 1) {
          if (newUnits[i].id === id) newUnits[i].delete = true;
        }
      }

      updatedHighSeasonPeriod = {
        ...updatedHighSeasonPeriod,
        units: newUnits,
      };

      return updatedHighSeasonPeriod;
    });
  };

  return (
    <form className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Titel: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='title'
            id='title'
            value={
              highSeasonPeriod && highSeasonPeriod.title
                ? highSeasonPeriod.title
                : ''
            }
            onChange={onChangeInput}
            type='text'
          />
        </div>
      </div>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='start'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Start: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='start'
            id='start'
            type='date'
            value={
              highSeasonPeriod && highSeasonPeriod.start
                ? moment(highSeasonPeriod.start).format('YYYY-MM-DD')
                : ''
            }
            onChange={e => {
              const { target } = e;
              const { value } = target;
              changeDate(value, 'start');
            }}
          />
        </div>
      </div>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='end'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Start: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='end'
            id='end'
            type='date'
            value={
              highSeasonPeriod && highSeasonPeriod.end
                ? moment(highSeasonPeriod.end).format('YYYY-MM-DD')
                : ''
            }
            onChange={e => {
              const { target } = e;
              const { value } = target;
              changeDate(value, 'end');
            }}
          />
        </div>
      </div>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Additionele prijs:{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='additionalPrice'
            id='additionalPrice'
            type='number'
            value={
              highSeasonPeriod && highSeasonPeriod.additionalPrice
                ? highSeasonPeriod.additionalPrice
                : ''
            }
            onChange={onChangeInput}
          />
        </div>
        <p className='text-sm mt-2 sm:col-span-3'>
          Je kan ook een negatieve waarde invullen.
        </p>
      </div>
      {units && units.length > 0 && (
        <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
          <div>
            <label
              htmlFor='title'
              className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
            >
              {' '}
              Units:{' '}
            </label>
          </div>
          <div className='sm:col-span-2'>
            {units.map(unit => {
              let isChecked = false;

              if (highSeasonPeriod && highSeasonPeriod.units) {
                for (let i = 0; i < highSeasonPeriod.units.length; i += 1) {
                  if (
                    highSeasonPeriod.units[i].id === unit.id &&
                    !highSeasonPeriod.units[i].delete
                  )
                    isChecked = true;
                }
              }
              return (
                <div key={`unit${unit.id}`} className='mb-2'>
                  <Checkbox
                    id={unit.id!.toString()}
                    defaultChecked={isChecked}
                    onChange={e => {
                      _onChangeUnitSelection(unit.id!, e.target.checked);
                    }}
                  >
                    {unit.title}
                  </Checkbox>
                </div>
              );
            })}
          </div>
          <p className='text-sm mt-2 sm:col-span-3'>
            Selecteer één of meerdere units wanneer deze regel zich beperkt tot
            die units. Wanneer geen units geselecteerd worden is deze regel op
            alle units van toeopassing.
          </p>
        </div>
      )}
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div className='sm:col-span-2'>
          <Switch
            label='enkel prijsaanpassing'
            // style={{ width: '2.5rem' }}
            onChange={_onChangeInputDiscount}
            checked={!!(highSeasonPeriod && highSeasonPeriod.discount)}
          />
        </div>
        <p className='text-sm mt-2 sm:col-span-3'>
          Wanneer je enkel een prijsaanpassing wil maar geen verandering van
          seizoen kan je dit vinkje aanzetten.
        </p>
      </div>
    </form>
  );
};

export const HighSeasonPeriods: React.FC = ({ ...props }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<any>();
  const [highSeasonHighSeasonPeriods, setHighSeasonPeriods] = useState<
    IHighSeasonPeriod[]
  >([]);
  const [filter, setFilter] = useState<string>('');
  const [selection, setSelection] = useState<IHighSeasonPeriod | undefined>();
  const [sorting, setSorting] = useState<
    { key: string; direction: string } | undefined
  >({ key: 'start', direction: 'asc' });
  const [debouncedFilter, setDebouncedFilter] = useState<string>('');

  useDebounce(filter, 500, (value: any) => {
    setDebouncedFilter(value);
  });
  const { data, refetch, loading } = useQuery(GET_HIGHSEASONPERIODS, {
    variables: {
      query: {
        where: {
          OR: [{ title: { contains: debouncedFilter } }],
        },
        orderBy: sorting ? { [sorting.key]: sorting.direction } : undefined,
      },
    },
    fetchPolicy: 'network-only',

    onCompleted: (x: any) => {
      if (x && x.findManyHighSeasonPeriods)
        setHighSeasonPeriods(x.findManyHighSeasonPeriods);
    },
  });

  const { data: units } = useQuery(GET_UNITS);

  useEffect(() => {
    if (data && data.findManyHighSeasonPeriods) {
      setHighSeasonPeriods(data.findManyHighSeasonPeriods);
    }
  }, [data]);

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column?: IColumn,
  ): void => {
    if (column && column.fieldName) {
      setSorting({
        key: column.fieldName,
        direction: column.isSortedDescending ? 'asc' : 'desc',
      });
    }
  };

  const editItem = (item: ISeason) => {
    setSelection(item);
    setOpen(true);
  };

  const deleteItem = (item: ISeason) => {
    setSelection(item);
    setOpenDialog(true);
  };

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Title',
      fieldName: 'title',
      render: (item: ISeason) => {
        return item.title;
      },
      onHeaderClick: onColumnClick,
      isSorted: sorting && sorting.key === 'title',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column2',
      name: 'Start',
      fieldName: 'start',
      render: (item: ISeason) => {
        return moment(item.start).format('DD-MM-YYYY');
      },
      onHeaderClick: onColumnClick,
      isSorted: sorting && sorting.key === 'start',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column3',
      name: 'End',
      fieldName: 'end',
      render: (item: ISeason) => {
        return moment(item.end).format('DD-MM-YYYY');
      },
      onHeaderClick: onColumnClick,
      isSorted: sorting && sorting.key === 'end',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column4',
      name: '',
      render: (item: ISeason) => {
        return (
          <div className='space-x-3'>
            <button
              className='text-indigo-600 hover:text-indigo-900'
              type='button'
              onClick={() => editItem(item)}
            >
              bewerken<span className='sr-only'>, {item.title}</span>
            </button>
            <button
              className='text-indigo-600 hover:text-indigo-900'
              type='button'
              onClick={() => deleteItem(item)}
            >
              verwijderen<span className='sr-only'>, {item.title}</span>
            </button>
          </div>
        );
      },
    },
  ];

  const [createHighSeasonPeriod] = useMutation(CREATE_HIGHSEASONPERIOD, {
    onError: err => {
      setError(err);
    },
    onCompleted: d => {
      setOpen(false);
      refetch();
    },
  });

  const [updateHighSeasonPeriod] = useMutation(UPDATE_HIGHSEASONPERIOD, {
    onError: err => {
      setError(err);
    },
    onCompleted: d => {
      setOpen(false);
      refetch();
    },
  });

  const [deleteHighSeasonPeriod] = useMutation(DELETE_HIGHSEASONPERIOD, {
    onError: err => {
      setError(err);
    },
    onCompleted: d => {
      setOpen(false);
      refetch();
    },
  });

  const save = React.useCallback(() => {
    const unitCreateObject: any = {
      connect: [],
    };

    const unitUpdateObject: any = {
      connect: [],
      disconnect: [],
    };

    if (selection && selection.units) {
      for (let i = 0; i < selection.units.length; i += 1) {
        const unit = selection.units[i];
        if (unit.id && unit.new && !unit.delete) {
          unitCreateObject.connect.push({ id: unit.id! });
          unitUpdateObject.connect.push({ id: unit.id! });
        }
        if (unit.id && unit.delete && !unit.new) {
          unitUpdateObject.disconnect.push({ id: unit.id! });
        }
      }
    }
    if (selection) {
      if (selection.id) {
        updateHighSeasonPeriod({
          variables: {
            data: {
              title: selection.title,
              start: selection.start,
              end: selection.end,
              additionalPrice: selection.additionalPrice
                ? Number(selection.additionalPrice)
                : undefined,
              discount: selection.discount,
              units: unitUpdateObject,
            },
            where: {
              id: selection.id,
            },
          },
        });
      } else {
        createHighSeasonPeriod({
          variables: {
            data: {
              title: selection.title,
              start: selection.start,
              end: selection.end,
              additionalPrice: selection.additionalPrice
                ? Number(selection.additionalPrice)
                : undefined,
              discount: selection.discount,
              units: unitCreateObject,
            },
          },
        });
      }
    }

    setSelection(undefined);
  }, [selection, createHighSeasonPeriod, updateHighSeasonPeriod]);

  const formComplete = React.useCallback(
    (highSeasonHighSeasonPeriod: IHighSeasonPeriod | undefined) => {
      if (!highSeasonHighSeasonPeriod) return false;

      let valid = true;
      if (
        !highSeasonHighSeasonPeriod.title ||
        highSeasonHighSeasonPeriod.title.length < 2
      )
        valid = false;
      if (
        !highSeasonHighSeasonPeriod.start ||
        highSeasonHighSeasonPeriod.start.length < 2
      )
        valid = false;
      if (
        !highSeasonHighSeasonPeriod.end ||
        highSeasonHighSeasonPeriod.end.length < 2
      )
        valid = false;

      return valid;
    },
    [],
  );

  const onRenderFooterContent = React.useCallback(
    () => (
      <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
        <div className='flex justify-end space-x-3'>
          <Button
            secondary
            size='lg'
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            size='lg'
            onClick={() => {
              save();
            }}
            className='mr-1'
            disabled={!formComplete(selection)}
          >
            Save
          </Button>
        </div>
      </div>
    ),
    [setOpen, formComplete, selection, save],
  );

  const confirmDeleteItem = () => {
    if (selection) {
      deleteHighSeasonPeriod({
        variables: {
          where: {
            id: selection.id,
          },
        },
      })
        .then(d => {
          refetch();
          setOpenDialog(false);
        })
        .catch(e => {
          setError(error);
          setOpenDialog(false);
        });
    }
  };

  const pages: INavItem[] = [
    { name: 'Dashboard', href: '/' },
    { name: 'Seizoenen', href: '/seizoenen' },
  ];

  const navigation = [
    {
      name: 'Nieuw',
      onClick: () => {
        setSelection(undefined);
        setOpen(true);
      },
      current: false,
      // disabled: !!(selection && selection.id),
    },
    /* {
      name: 'Bewerken',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faPen} />,
      disabled: !selection || !selection.id,
    },
    {
      name: 'Verwijderen',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faTrash} />,
      disabled: !selection || !selection.id,
    }, */
  ];

  return (
    <div {...props}>
      <PageHeader
        title='Hoogseizoen(en) & prijsaanpassingen beheren'
        description='De periodes hieronder aangegeven zijn hoogseizoen of prijsaanpassing. Wanneer korting geselecteerd is zal het seizoen niet beinvloed worden tenzij een andere regel het hoogseizoen bepaald.'
        pages={pages}
        home={{ href: '/' }}
        loading={loading}
      >
        <div className='flex align-middle'>
          {navigation.map((item, index) => {
            return (
              <Button
                size='lg'
                primary
                onClick={item.onClick}
                // disabled={item.disabled}
                className={index > 0 ? 'ml-2' : ''}
              >
                {item.name}
              </Button>
            );
          })}
          <div className='relative ml-5'>
            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
              <SearchIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              id='search'
              name='search'
              className='block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-redo-500 focus:border-redo-500 sm:text-sm'
              placeholder='Search'
              type='search'
              autoComplete='off'
              onChange={e => {
                const { target } = e;
                const { value } = target;
                setFilter(value);
              }}
            />
          </div>
        </div>
      </PageHeader>
      <Table items={highSeasonHighSeasonPeriods} columns={columns} />

      <Notification
        show={!!error}
        dismiss={() => {
          setError(undefined);
        }}
        level='error'
        title='Er ging iets mis'
      >
        {error?.message}
      </Notification>
      <Slideover
        title={
          selection && selection.id ? 'Seizoen bewerken' : 'Seizoen toevoegen'
        }
        description='Voeg een nieuw seizoen toe of bewerk een bestaand seizoen.'
        open={open}
        setOpen={setOpen}
        actions={onRenderFooterContent()}
        lightDismiss
      >
        <CreateUpdateForm
          handleChange={setSelection}
          highSeasonPeriod={selection}
          units={units && units.findManyUnits}
        />
      </Slideover>
      <Dialog
        open={openDialog}
        setOpen={setOpenDialog}
        title='Seizoen verwijderen'
        icon={
          <ExclamationIcon
            className='h-6 w-6 text-red-600'
            aria-hidden='true'
          />
        }
        description={
          <span>
            Je staat op het punt om het geselecteerde seizoen{' '}
            <strong>{selection?.title} </strong> te verwijderen. Deze actie is
            kan niet worden teruggedraaid.
          </span>
        }
        actions={
          <div className='space-x-3'>
            <Button
              secondary
              style={{ marginBottom: 2 }}
              onClick={e => {
                e.preventDefault();
                confirmDeleteItem();
                // dismissPanel();
              }}
            >
              Verwijderen
            </Button>
          </div>
        }
        lightDismiss
      />
    </div>
  );
};

export default HighSeasonPeriods;

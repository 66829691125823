/* eslint-disable no-nested-ternary */
import React from 'react';
import moment, { Moment } from 'moment-timezone';
import { IDay, IBookingInfo } from '../types';

moment.tz('Europe/Brussels');

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface CalenderProps {
  currentSelection?: Moment;
  currentSelectionDuration: number;
  setSelection: (date: Moment) => void;
  year: number;
  month: number;
  dateValidation?: IDay[];
  bookingInfo?: IBookingInfo[];
  loading?: boolean;
  hideSelection?: boolean;
}

const calender: React.FC<CalenderProps> = ({
  currentSelection,
  currentSelectionDuration,
  setSelection = date => ({}),
  year,
  month,
  dateValidation = [],
  loading = false,
  hideSelection = false,
  // bookingInfo = [],
  ...props
}) => {
  const validateDate = (date: Moment): boolean => {
    const now = moment();
    if (now.isAfter(moment(date))) {
      return false;
    }

    if (dateValidation && dateValidation.length > 0) {
      for (let i = 0; i < dateValidation.length; i += 1) {
        const currentDate = dateValidation[i];
        if (moment(currentDate.date).dayOfYear() === moment(date).dayOfYear()) {
          return currentDate.valid;
        }
      }
      return false;
    }

    return true;
  };

  const dateBooked = (date: Moment): boolean => {
    const now = moment();
    if (now.isAfter(moment(date))) {
      return false;
    }

    if (dateValidation && dateValidation.length > 0) {
      for (let i = 0; i < dateValidation.length; i += 1) {
        const currentDate = dateValidation[i];
        if (moment(currentDate.date).dayOfYear() === moment(date).dayOfYear()) {
          // console.log(currentDate);
          return !!currentDate.booked;
        }
      }
      return false;
    }

    return false;
  };

  const dateAvailable = (date: Moment): boolean => {
    const now = moment();
    if (now.isAfter(moment(date))) {
      return false;
    }

    if (dateValidation && dateValidation.length > 0) {
      for (let i = 0; i < dateValidation.length; i += 1) {
        const currentDate = dateValidation[i];
        if (moment(currentDate.date).dayOfYear() === moment(date).dayOfYear()) {
          // console.log(currentDate);
          return !!currentDate.available;
        }
      }
      return false;
    }

    return false;
  };
  /* 
    const getBookingInfo = (date: Moment) => {
      if (bookingInfo && bookingInfo.length > 0) {
        for (let i = 0; i < bookingInfo.length; i += 1) {
          const currentDate = bookingInfo[i];

          if (moment(currentDate.date).dayOfYear() === moment(date).dayOfYear()) {
            return {
              booked: currentDate.booked,
              temporary: currentDate.temporary,
            };
          }
        }
      }

      return {
        booked: false,
        temporary: false,
      };
    };
  */
  const createTable = () => {
    const table = [];

    // const month = offset ? 4 + offset : 4;
    const firstDayOfMonth = moment()
      .set('month', month - 1)
      .set('year', year)
      .startOf('month');

    const dayNumber = firstDayOfMonth.day();
    const daysInMonth = firstDayOfMonth.daysInMonth();

    const offset = dayNumber === 0 ? 7 : dayNumber;

    const calenderNumberOfDays = offset - 1 + daysInMonth;

    const calenderNumberOfRows = Math.ceil(calenderNumberOfDays / 7);

    const calenderNumberOfDaysToShow = calenderNumberOfRows * 7;

    const calenderDaysAfterCalender =
      calenderNumberOfDaysToShow - calenderNumberOfDays;

    const days: Moment[] = [];

    const startDate = firstDayOfMonth;

    const iterationDay = moment(startDate);

    while (iterationDay.month() === month - 1) {
      days.push(moment(iterationDay));
      iterationDay.add(1, 'day');
    }

    for (let i = 0; i < offset - 1; i += 1) {
      const currentDay = moment(startDate).subtract(offset - (i + 1), 'day');
      table.push({
        day: currentDay,
        today: currentDay.isSame(moment(), 'day'),
        start: false,
        booked: false,
        active: false,
        selection: false,
        selected: false,
        currentMonth: false,
      });
    }

    for (let i = 0; i < days.length; i += 1) {
      const day = days[i];
      const isValid = validateDate(day);
      const isBooked = dateBooked(day);
      const isAvailable = dateAvailable(day);

      const isClickable = isValid && dateValidation.length > 0;
      const beforeToday = day.isBefore(moment().startOf('day'));

      // const currentBookingInfo = getBookingInfo(day);

      const isSelection = (d: Moment) => {
        if (currentSelection) {
          const endOfPeriod = moment(currentSelection).add(
            currentSelectionDuration - 1,
            'days',
          );
          if (
            d.isSameOrAfter(currentSelection) &&
            d.isSameOrBefore(endOfPeriod)
          )
            return true;
        }
        return false;
      };

      const isCurrentSelection = (currentDay: Moment) => {
        if (currentDay.isSame(currentSelection)) return true;

        return false;
      };

      table.push({
        day,
        today: day.isSame(moment(), 'day'),
        start: isClickable,
        // booked: currentBookingInfo.booked,
        booked: isBooked,
        active: !beforeToday,
        available: isAvailable,
        selection: isSelection(day),
        selected: isCurrentSelection(day),
        currentMonth: true,
      });

      /*
      table.push(
        <button
          key={`date-${i}`}
          style={{
            width: '12%',
            margin: '1%',
            cursor: isClickable ? 'pointer' : 'initial',
            outline: isClickable && !beforeToday ? '2px solid' : '1px solid',
            outlineColor: isSelection(day)
              ? isCurrentSelection(day)
                ? '#314934'
                : '#ecd637'
              : '#314934',
            opacity: beforeToday && !getBookingInfo(day).booked ? 0.6 : 1,
            paddingTop: 2,
            paddingBottom: 1,
            backgroundColor: getBackground(),
          }}
        
          onClick={() => {
            if (isClickable) setSelection(day);
          }}
          type='button'
        >
          {day.date()}
        </button>,
      );
      */
    }

    for (let i = 0; i < calenderDaysAfterCalender; i += 1) {
      const currentDay = moment(startDate)
        .endOf('month')
        .add(i + 1, 'day');
      table.push({
        day: currentDay,
        today: currentDay.isSame(moment(), 'day'),
        start: false,
        booked: false,
        active: false,
        available: false,
        selection: false,
        selected: false,
        currentMonth: false,
      });
    }

    return table;
  };

  const days = createTable();

  const dayLabels: string[] = ['ma', 'di', 'woe', 'do', 'vr', 'za', 'zo'];
  return (
    <div style={{ position: 'relative' }}>
      <div className='mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500'>
        {dayLabels.map((label, i) => {
          return <div key={`label-${i}`}>{label}</div>;
        })}
      </div>
      <div className='isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200'>
        {days.map((item, dayIdx) => {
          return (
            <button
              key={item.day.toISOString()}
              type='button'
              className={classNames(
                'py-1.5 focus:z-10',
                item.currentMonth ? 'bg-white' : 'bg-gray-50',
                item.selected || item.today ? 'font-semibold' : '',
                item.selected || item.selection ? 'text-white' : '',
                !item.selected && !item.selection && item.active && !item.today
                  ? 'text-gray-900'
                  : '',
                !item.currentMonth ? 'text-gray-400' : '',
                item.today && !item.selected ? 'font-bold text-red-500' : '',
                dayIdx === 0 ? 'rounded-tl-lg' : '',
                dayIdx === 6 ? 'rounded-tr-lg' : '',
                dayIdx === days.length - 7 ? 'rounded-bl-lg' : '',
                dayIdx === days.length - 1 ? 'rounded-br-lg' : '',
                !item.start ? 'cursor-default' : '',
              )}
              disabled={!item.active && item.start}
              onClick={() => {
                if (item.start) setSelection(item.day);
              }}
            >
              <time
                dateTime={item.day.toISOString()}
                className={classNames(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  !item.available && item.active ? 'bg-gray-200' : '', // This line checks if the item is not available and sets the bg color to grey
                  !item.selection && !item.selected && item.booked
                    ? 'bg-yellow-500'
                    : '',
                  item.selection && !item.selected ? 'bg-gray-700' : '',
                  item.selected && item.available ? 'bg-gray-900' : '',
                  item.start && !item.selected
                    ? 'hover:bg-red-200 bg-red-400'
                    : '',
                )}
              >
                {item.day.date()}
              </time>
            </button>
          );
        })}
      </div>

      <div className='flex gap-3 justify-start items-center text-xs mt-4'>
        <div
          className={classNames(
            'flex h-5 w-5 items-center justify-center rounded-full',
            'bg-yellow-500',
          )}
        >
          x
        </div>

        <div>Reeds geboekt</div>
        <div
          className={classNames(
            'flex h-5 w-5 items-center justify-center rounded-full',
            'bg-gray-200',
          )}
        >
          x
        </div>

        <div>Niet beschikbaar</div>
        {!hideSelection && (
          <>
            <div
              className={classNames(
                'flex h-5 w-5 items-center justify-center rounded-full',
                'bg-red-400',
              )}
            >
              x
            </div>
            <div>Aankomst mogelijk</div>
            <div
              className={classNames(
                'flex h-5 w-5 items-center justify-center rounded-full',
                'bg-gray-900 text-white',
              )}
            >
              x
            </div>
            <div>Huidige selectie</div>
          </>
        )}
      </div>
    </div>
  );
};

export default calender;

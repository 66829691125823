/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Routes,
  Route,
  Outlet,
  Link,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
/*
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
];
*/

export interface INavItem {
  name: string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

export interface NavbarProps {
  navigation: INavItem[];
  notifications?: boolean;
  showNotifications?: boolean;
  userNavigation?: INavItem[];
  dark?: boolean;
  LogoDark?: React.FC;
  LogoLight?: React.FC;
}

interface NavItemProps {
  item: INavItem;
  dark?: boolean;
}

const DefaultLogo = () => <div />;

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const colorMode = (dark: string, light: string, isDark?: boolean) => {
  return isDark ? dark : light;
};

const NavItem: React.FC<NavItemProps> = ({ item, dark }) => {
  const resolved = useResolvedPath(item.href || '');
  const match = useMatch({ path: resolved.pathname, end: true });

  if (item.href) {
    return (
      <Link
        key={item.name}
        to={item.href}
        className={colorMode(
          classNames(
            match
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'px-3 py-2 rounded-md text-sm font-medium',
          ),
          classNames(
            match
              ? 'border-red-500 text-gray-900'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
          ),
          dark,
        )}
        aria-current={match ? 'page' : undefined}
        onClick={item.onClick}
      >
        {item.name}
      </Link>
    );
  }

  return (
    <button
      key={item.name}
      className={colorMode(
        classNames(
          match
            ? 'bg-gray-900 text-white'
            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
          'px-3 py-2 rounded-md text-sm font-medium',
        ),
        classNames(
          match
            ? 'border-red-500 text-gray-900'
            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
          'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
        ),
        dark,
      )}
      aria-current={match ? 'page' : undefined}
      onClick={item.onClick}
      type='button'
    >
      {item.name}
    </button>
  );
};

const Navbar: React.FC<NavbarProps> = ({
  LogoDark = DefaultLogo,
  LogoLight = DefaultLogo,
  navigation,
  notifications,
  showNotifications,
  userNavigation,
  dark,
}) => {
  const { user } = useAuth0();
  return (
    <Disclosure
      as='nav'
      className={colorMode('bg-gray-800', 'bg-white shadow', dark)}
    >
      {({ open }) => (
        <>
          <div className='2xl:max-w-screen-2xl xl:max-w-screen-xl md:max-w-screen-md mx-auto sm:px-6 md:px-0'>
            <div
              className={classNames(
                'relative flex justify-between h-16',
                colorMode('items-center', 'items-stretch', dark),
              )}
            >
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button
                  className={classNames(
                    'inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white marker:focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white',
                    colorMode(
                      'hover:text-gray-500',
                      'hover:text-gray-700',
                      dark,
                    ),
                  )}
                >
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='flex-shrink-0 flex items-center'>
                  <Link to='/'>{dark ? <LogoDark /> : <LogoLight />}</Link>
                </div>
                <div
                  className={classNames(
                    'hidden sm:ml-6 sm:flex',
                    colorMode('sm:space-x-4', 'sm:space-x-8', dark),
                  )}
                >
                  {navigation.map(item => (
                    <NavItem item={item} dark={dark} />
                  ))}
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                {showNotifications && (
                  <button
                    type='button'
                    className={colorMode(
                      'bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white',
                      'bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
                      dark,
                    )}
                  >
                    <span className='sr-only'>View notifications</span>
                    <BellIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                )}

                {/* Profile dropdown */}
                <Menu as='div' className='ml-3 relative'>
                  <div>
                    <Menu.Button
                      className={colorMode(
                        'bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white',
                        'bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
                        dark,
                      )}
                    >
                      <span className='sr-only'>Open user menu</span>
                      {user && user.picture && (
                        <img
                          className='h-8 w-8 rounded-full'
                          src={user.picture}
                          alt={user.nickname}
                        />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20'>
                      <div className='px-4 py-3'>
                        <p className='text-sm'>Aangemeld als</p>
                        <p className='text-sm font-medium text-gray-900 truncate'>
                          {user?.email}
                        </p>
                      </div>
                      <div className='py-1'>
                        {userNavigation?.map(item => {
                          return (
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700',
                                    item.className || '',
                                  )}
                                  onClick={item.onClick}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          );
                        })}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              {navigation.map(item => (
                <Disclosure.Button
                  key={item.name}
                  as='a'
                  href={item.href}
                  className={classNames(
                    'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium',
                  )}
                  onClick={item.onClick}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;

export { Navbar, NavItem };

import { gql } from '@apollo/client';

export const GET_UNIT = gql`
  query findOneUnit($where: UnitWhereUniqueInput) {
    findOneUnit(where: $where) {
      id
      createdAt
      updatedAt
      title
      body
      bookings {
        unit {
          id
          title
        }
        start
        end
        price
        user {
          id
          profile {
            id
            firstName
            lastName
          }
        }
      }
      externalSources {
        id
        createdAt
        updatedAt
        title
        url
      }
      unitPeriods {
        id
        price
        season {
          id
          title
        }
        period {
          id
          name
        }
        unit {
          id
          title
        }
      }
      children {
        id
        title
      }
      parent {
        id
        title
      }
      minPersons
      maxPersons
      guaranteeAmount
      depositAmount
    }
  }
`;

export const GET_UNITS = gql`
  query findManyUnits($query: UnitFindArgs) {
    findManyUnits(query: $query) {
      id
      createdAt
      updatedAt
      title
      body
      bookings {
        unit {
          id
          title
        }
        start
        end
        price
        user {
          id
          profile {
            id
            firstName
            lastName
          }
        }
      }
      externalSources {
        id
        createdAt
        updatedAt
        title
        url
      }
      unitPeriods {
        id
        price
        season {
          id
          title
        }
        period {
          id
          name
        }
        unit {
          id
          title
        }
      }
      children {
        id
        title
      }
      parent {
        id
        title
      }
      minPersons
      maxPersons
      guaranteeAmount
      depositAmount
    }
  }
`;

export const CREATE_UNIT = gql`
  mutation createUnit($data: UnitCreateInput) {
    createUnit(data: $data) {
      id
      createdAt
      updatedAt
      title
      body
      bookings {
        unit {
          id
          title
        }
        start
        end
        price
        user {
          id
          profile {
            id
            firstName
            lastName
          }
        }
      }
      externalSources {
        id
        createdAt
        updatedAt
        title
        url
      }
      unitPeriods {
        id
        price
        season {
          id
          title
        }
        period {
          id
          name
        }
        unit {
          id
          title
        }
      }
      children {
        id
        title
      }
      parent {
        id
        title
      }
      minPersons
      maxPersons
      guaranteeAmount
      depositAmount
    }
  }
`;

export const UPDATE_UNIT = gql`
  mutation updateUnit($data: UnitUpdateInput, $where: UnitWhereUniqueInput) {
    updateUnit(data: $data, where: $where) {
      id
      createdAt
      updatedAt
      title
      body
      bookings {
        unit {
          id
          title
        }
        start
        end
        price
        user {
          id
          profile {
            id
            firstName
            lastName
          }
        }
      }
      externalSources {
        id
        createdAt
        updatedAt
        title
        url
      }
      unitPeriods {
        id
        price
        season {
          id
          title
        }
        period {
          id
          name
        }
        unit {
          id
          title
        }
      }
      children {
        id
        title
      }
      parent {
        id
        title
      }
      minPersons
      maxPersons
      guaranteeAmount
      depositAmount
    }
  }
`;

export const DELETE_UNIT = gql`
  mutation deleteUnit($where: UnitWhereUniqueInput) {
    deleteUnit(where: $where) {
      count
    }
  }
`;

export const DELETE_UNITS = gql`
  mutation deleteUnits($where: UnitWhereInput) {
    deleteUnits(where: $where) {
      count
    }
  }
`;

import React from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Checkbox: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({ className, children, ...props }) => {
  return (
    <div className='relative flex items-start'>
      <div className='flex items-center h-5'>
        <input
          className={classNames(
            'focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded',
            className || '',
          )}
          type='checkbox'
          {...props}
        />
      </div>
      {children && <div className='ml-3 text-sm'>{children}</div>}
    </div>
  );
};

export { Checkbox };

/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useEffect, useRef } from 'react';
import { useScrollPosition, Scroll } from './hooks/useScrollPosition';

export interface IColumn {
  key: string | number;
  onHeaderClick?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    fieldName?: IColumn,
  ) => void;
  sortable?: boolean;
  isSortedDescending?: boolean;
  isSorted?: boolean;
  name: string;
  fieldName?: string;
  render: (item: any) => any;
}

interface TableProps {
  columns: IColumn[];
  items: any[];
  lazyLoading?: boolean;
  loadMore?: boolean;
  loadMoreCallback?: () => void;
  // shimmer?: boolean;
  loading?: boolean;
  // shimmerLength?: number;
}

const Table: React.FC<TableProps> = ({
  columns,
  items,
  loadMore,
  loadMoreCallback = () => ({}),
  lazyLoading,
  loading,
}) => {
  const ref = useRef(null);
  const scroll = useScrollPosition({
    wait: 500,
    element: ref,
  });

  const isRefBottomAboveViewport = (input: Scroll): boolean => {
    if (input && input.rect && input.viewport) {
      if (input.rect.bottom < input.viewport.h) return true;
    }
    return false;
  };

  useEffect(() => {
    if (loadMore && lazyLoading && !loading) {
      const shouldLoadMore = isRefBottomAboveViewport(scroll);

      if (shouldLoadMore) {
        console.log('loadmore');
        loadMoreCallback();
      }
    }
  }, [scroll]);

  return (
    <div className='mt-8 flex flex-col'>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-300' ref={ref}>
              <thead className='bg-gray-50'>
                <tr>
                  {columns.map((col, i) => {
                    return (
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        key={`col-${i}_${col.name}`}
                        onClick={
                          col.sortable
                            ? e => {
                                if (col.onHeaderClick)
                                  col.onHeaderClick(e, col);
                              }
                            : undefined
                        }
                      >
                        <a
                          href='#'
                          className={`group inline-flex ${
                            col.sortable
                              ? 'hover:cursor-pointer'
                              : 'hover:cursor-default'
                          }`}
                        >
                          {col.name}
                          {col.sortable &&
                            col.isSorted &&
                            col.isSortedDescending && (
                              <span className='ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible'>
                                <ChevronDownIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            )}
                          {col.sortable &&
                            col.isSorted &&
                            !col.isSortedDescending && (
                              <span className='ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible'>
                                <ChevronDownIcon
                                  className='h-5 w-5 rotate-180'
                                  aria-hidden='true'
                                />
                              </span>
                            )}
                          {col.sortable && !col.isSorted && (
                            <span className='invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible'>
                              <ChevronDownIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          )}
                        </a>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {items.map((item, i) => (
                  <tr key={`${item.id}_${i}`}>
                    {columns.map((col, index) => {
                      return (
                        <td
                          className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'
                          key={`${item.id}_${col.key}_${index}`}
                        >
                          {col.render(item)}
                        </td>
                      );
                    })}
                  </tr>
                ))}
                {loading && (
                  <tr key='loading'>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                      <svg
                        className='animate-spin ml-2 mr-3 h-6 w-6 text-red'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <circle
                          className='opacity-25'
                          cx='12'
                          cy='12'
                          r='10'
                          stroke='currentColor'
                          strokeWidth='4'
                        />
                        <path
                          className='opacity-75'
                          fill='currentColor'
                          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                        />
                      </svg>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Table };

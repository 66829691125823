/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { SearchIcon, ExclamationIcon } from '@heroicons/react/solid';
import _ from 'lodash';
import { Input } from '../components/input';
import useDebounce from '../components/hooks/useDebounce';
import { Button } from '../components/button';
import { PageHeader } from '../components/pageheader';
import { Table, IColumn } from '../components/table';
import { Slideover } from '../components/slideover';
import { Notification } from '../components/notification';
import { Dialog } from '../components/dialog';
import { Select } from '../components/select';
import { IOption } from '../types';
import {
  GET_OPTIONS,
  CREATE_OPTION,
  UPDATE_OPTION,
  DELETE_OPTION,
} from '../graphql/option';
import { INavItem } from '../components/navbar';
import { Textarea } from '../components/textarea';

interface ICreateUpdate {
  option?: IOption;
  handleChange?: React.Dispatch<React.SetStateAction<IOption | undefined>>;
}

const CreateUpdateForm: React.FC<ICreateUpdate> = ({
  option,
  handleChange = () => ({}),
}) => {
  const onChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value, id } = target;

    handleChange(prevState => {
      let updatedOption: IOption | undefined = _.cloneDeep(prevState);

      if (!updatedOption) updatedOption = {};

      let parsedValue: string | number = value;

      if (id === 'fee') parsedValue = parseFloat(value);

      updatedOption = {
        ...updatedOption,
        [id]: parsedValue,
      };
      return updatedOption;
    });
  };

  const onChangeTextInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = event;
    const { value, id } = target;

    handleChange(prevState => {
      let updatedOption: IOption | undefined = _.cloneDeep(prevState);

      if (!updatedOption) updatedOption = {};

      updatedOption = {
        ...updatedOption,
        [id]: value,
      };
      return updatedOption;
    });
  };

  return (
    <form className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Naam: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='name'
            id='name'
            value={option && option.name ? option.name : ''}
            onChange={onChangeInput}
            type='text'
          />
        </div>
      </div>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Type: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Select
            defaultValue={option?.type || 'per_person'}
            onChange={onChangeInput}
            name='type'
            id='type'
          >
            <option value='per_person'>Per persoon</option>
            <option value='amount'>Per stuk</option>
          </Select>
        </div>
      </div>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Prijs: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='fee'
            id='fee'
            value={option && option.fee ? option.fee : ''}
            onChange={onChangeInput}
            type='number'
            step='.01'
          />
        </div>
      </div>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Omschrijving: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Textarea
            name='description'
            id='description'
            value={option && option.description ? option.description : ''}
            onChange={onChangeTextInput}
          />
        </div>
      </div>
    </form>
  );
};

export const Options: React.FC = ({ ...props }) => {
  const [open, setOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [error, setError] = useState<any>();

  const [options, setOptions] = useState<IOption[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [selection, setSelection] = useState<IOption | undefined>();
  const [sorting, setSorting] = useState<
    { key: string; direction: string } | undefined
  >({ key: 'name', direction: 'asc' });
  const [debouncedFilter, setDebouncedFilter] = useState<string>('');

  useDebounce(filter, 500, (value: any) => {
    setDebouncedFilter(value);
  });

  const { refetch, loading } = useQuery(GET_OPTIONS, {
    variables: {
      query: {
        where: {
          OR: [{ name: { contains: debouncedFilter } }],
        },
        // orderBy: sorting ? { [sorting.key]: sorting.direction } : undefined,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (x: any) => {
      if (x && x.findManyOptions) setOptions(x.findManyOptions);
    },
    onError: err => setError(err),
  });

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column?: IColumn,
  ): void => {
    if (column && column.fieldName) {
      setSorting({
        key: column.fieldName,
        direction: column.isSortedDescending ? 'asc' : 'desc',
      });
    }
  };

  const editItem = (item: IOption) => {
    setSelection(item);
    setOpen(true);
  };

  const deleteItem = (item: IOption) => {
    setSelection(item);
    setOpenDialog(true);
  };

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      render: (item: IOption) => {
        return item.name;
      },
      onHeaderClick: onColumnClick,
      isSorted: sorting && sorting.key === 'name',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column2',
      name: 'Type',
      fieldName: 'type',
      render: (item: IOption) => {
        let parsedType = 'Per persoon';

        switch (item.type) {
          case 'amount':
            parsedType = 'Per stuk';
            break;
          case 'per_person':
            parsedType = 'Per persoon';
            break;
          default:
            parsedType = 'Per persoon';
        }

        return parsedType;
      },
      onHeaderClick: onColumnClick,
      isSorted: sorting && sorting.key === 'type',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column3',
      name: 'Prijs',
      fieldName: 'fee',
      render: (item: IOption) => {
        return `€ ${item.fee}`;
      },
      onHeaderClick: onColumnClick,
      isSorted: sorting && sorting.key === 'fee',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column4',
      name: 'Omschrijving',
      fieldName: 'description',
      render: (item: IOption) => {
        return `${item.description}`;
      },
      onHeaderClick: onColumnClick,
      isSorted: sorting && sorting.key === 'description',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },

    {
      key: 'column5',
      name: '',
      render: (item: IOption) => {
        return (
          <div className='space-x-3'>
            <button
              className='text-indigo-600 hover:text-indigo-900'
              type='button'
              onClick={() => editItem(item)}
            >
              bewerken<span className='sr-only'>, {item.name}</span>
            </button>
            <button
              className='text-indigo-600 hover:text-indigo-900'
              type='button'
              onClick={() => deleteItem(item)}
            >
              verwijderen<span className='sr-only'>, {item.name}</span>
            </button>
          </div>
        );
      },
    },
  ];

  const [createOption] = useMutation(CREATE_OPTION, {
    onError: err => {
      setError(err);
    },
    onCompleted: d => {
      setOpen(false);
      refetch();
    },
  });

  const [updateOption] = useMutation(UPDATE_OPTION, {
    onError: err => {
      setError(err);
    },
    onCompleted: d => {
      setOpen(false);
      refetch();
    },
  });

  const [deleteOption] = useMutation(DELETE_OPTION, {
    onError: err => {
      setError(err);
    },
    onCompleted: d => {
      setOpenDialog(false);
      refetch();
    },
  });

  const save = React.useCallback(() => {
    if (selection) {
      if (selection.id) {
        updateOption({
          variables: {
            data: {
              name: selection.name,
              type: selection.type || 'per_person',
              fee: selection.fee,
              description: selection.description,
            },
            where: {
              id: selection.id,
            },
          },
        });
      } else {
        createOption({
          variables: {
            data: {
              name: selection.name,
              type: selection.type || 'per_person',
              fee: selection.fee,
              description: selection.description,
            },
          },
        });
      }
    }
  }, [selection, createOption, updateOption]);

  const formComplete = React.useCallback((option: IOption | undefined) => {
    if (!option) return false;

    let valid = true;
    if (!option.name || option.name.length < 2) valid = false;

    return valid;
  }, []);

  const onRenderFooterContent = React.useCallback(
    () => (
      <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
        <div className='flex justify-end space-x-3'>
          <Button
            secondary
            size='lg'
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            size='lg'
            onClick={() => {
              save();
            }}
            className='mr-1'
            disabled={!formComplete(selection)}
          >
            Save
          </Button>
        </div>
      </div>
    ),
    [setOpen, formComplete, selection, save],
  );
  const confirmDeleteItem = () => {
    if (selection) {
      deleteOption({
        variables: {
          where: {
            id: selection.id,
          },
        },
      });
    }
  };

  const pages: INavItem[] = [
    { name: 'Dashboard', href: '/' },
    { name: 'Options', href: '/optiones' },
  ];

  const navigation = [
    {
      name: 'Nieuw',
      onClick: () => {
        setSelection(undefined);
        setOpen(true);
      },
      current: false,
      // disabled: !!(selection && selection.id),
    },
    /* {
      name: 'Bewerken',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faPen} />,
      disabled: !selection || !selection.id,
    },
    {
      name: 'Verwijderen',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faTrash} />,
      disabled: !selection || !selection.id,
    }, */
  ];

  return (
    <div {...props}>
      <PageHeader
        title='Opties beheren'
        pages={pages}
        home={{ href: '/' }}
        loading={loading}
      >
        <div className='flex align-middle'>
          {navigation.map((item, index) => {
            return (
              <Button
                size='lg'
                primary
                onClick={item.onClick}
                // disabled={item.disabled}
                className={index > 0 ? 'ml-2' : ''}
              >
                {item.name}
              </Button>
            );
          })}
          <div className='relative ml-5'>
            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
              <SearchIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              id='search'
              name='search'
              className='block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-redo-500 focus:border-redo-500 sm:text-sm'
              placeholder='Search'
              type='search'
              autoComplete='off'
              onChange={e => {
                const { target } = e;
                const { value } = target;
                setFilter(value);
              }}
            />
          </div>
        </div>
      </PageHeader>
      <Table items={options} columns={columns} />
      <Notification
        show={!!error}
        dismiss={() => {
          setError(undefined);
        }}
        level='error'
        title='Er ging iets mis'
      >
        {error?.message}
      </Notification>
      <Slideover
        title={
          selection && selection.id ? 'Seizoen bewerken' : 'Seizoen toevoegen'
        }
        description='Voeg een nieuwe optione toe of bewerk een bestaande optione.'
        open={open}
        setOpen={setOpen}
        actions={onRenderFooterContent()}
        lightDismiss
      >
        <CreateUpdateForm handleChange={setSelection} option={selection} />
      </Slideover>
      <Dialog
        open={openDialog}
        setOpen={setOpenDialog}
        title='Seizoen verwijderen'
        icon={
          <ExclamationIcon
            className='h-6 w-6 text-red-600'
            aria-hidden='true'
          />
        }
        description={
          <span>
            Je staat op het punt om de geselecteerde optione{' '}
            <strong>{selection?.name} </strong> te verwijderen. Deze actie is
            kan niet worden teruggedraaid.
          </span>
        }
        actions={
          <div className='space-x-3'>
            <Button
              secondary
              style={{ marginBottom: 2 }}
              onClick={e => {
                e.preventDefault();
                confirmDeleteItem();
                // dismissPanel();
              }}
            >
              Verwijderen
            </Button>
          </div>
        }
        lightDismiss
      />
    </div>
  );
};

export default Options;

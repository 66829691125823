/* This example requires Tailwind CSS v2.0+ */
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { INavItem } from './navbar';

const demoPages = [
  { name: 'Projects', href: '#', current: false },
  { name: 'Project Nero', href: '#', current: true },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface BreadcrumbProps {
  home?: {
    href?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  };
  pages?: INavItem[];
  separator?: React.FC | JSX.Element;
  dark?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({
  home = { href: '/' },
  pages = demoPages,
  separator,
  dark,
}) => {
  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol className='flex items-center space-x-4'>
        <li>
          <div>
            <a
              href={home.href}
              className='text-gray-400 hover:text-gray-500'
              onClick={home.onClick}
            >
              <HomeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
              <span className='sr-only'>Home</span>
            </a>
          </div>
        </li>
        {pages.map(page => (
          <li key={page.name}>
            <div className='flex items-center'>
              {separator || (
                <ChevronRightIcon
                  className='flex-shrink-0 h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              )}
              <a
                href={page.href}
                className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                // aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export { Breadcrumbs };

const Logo: React.FC = ({ ...props }) => {
  return (
    <div style={{ maxWidth: '50px' }} {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 177.547 151.699'
        width='100%'
      >
        <path
          id='Path_888'
          // dataName='Path 888'
          d='M174.387,92.451,158.5,83.291,145.9,90.561,158.5,97.914,88.625,138.253,18.914,97.788,44.126,83.123,62.363,93.67a52.483,52.483,0,0,0,52.441,0l59.584-34.288a6.3,6.3,0,0,0,0-10.925L91.9.848a6.261,6.261,0,0,0-6.3,0L3.157,48.457a6.3,6.3,0,0,0,0,10.925L31.688,75.854,3.157,92.451a6.3,6.3,0,0,0,0,10.925L85.6,150.859a6.345,6.345,0,0,0,6.3,0l82.485-47.608a6.3,6.3,0,0,0,0-10.925Zm-65.719-9.707a39.919,39.919,0,0,1-39.835,0L56.9,75.854l11.934-6.891a39.919,39.919,0,0,1,39.835,0l11.976,6.891ZM18.914,53.919,88.751,13.58,158.63,53.919,133.208,68.584,114.971,58.037a52.651,52.651,0,0,0-52.441,0L44.294,68.584Z'
          transform='translate(0.002 0.003)'
          fill='#FC2901'
        />
      </svg>
    </div>
  );
};

export default Logo;

export { Logo };

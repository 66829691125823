import { gql } from '@apollo/client';

export const GET_PAYMENTTYPES = gql`
  query findManyPaymentTypes($query: PaymentTypeFindArgs) {
    findManyPaymentTypes(query: $query) {
      id
      label
      slug
    }
  }
`;

/* This example requires Tailwind CSS v2.0+ */
import { INavItem } from './navbar';
import { Breadcrumbs } from './breadcrumbs';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface PageHeaderProps {
  pages?: INavItem[]; // for breadcrumbs
  home?: {
    href?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  };
  title: string;
  description?: string;
  dark?: boolean;
  loading?: boolean;
}
const PageHeader: React.FC<PageHeaderProps> = ({
  dark,
  home,
  pages,
  title,
  description,
  children,
  loading,
}) => {
  // eslint-disable-next-line no-param-reassign
  return (
    <div className='mb-5'>
      <Breadcrumbs pages={pages} home={home} />
      <div className='mt-2 md:flex md:items-center md:justify-between'>
        <div className='flex-1 min-w-0'>
          <h2
            className={classNames(
              'text-2xl font-bold leading-7 flex items-center sm:text-3xl sm:truncate',
              dark ? 'text-white' : 'text-gray-900',
            )}
          >
            {title}
            {loading && (
              <svg
                className='animate-spin ml-2 mr-3 h-6 w-6 text-red'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                />
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                />
              </svg>
            )}
          </h2>
          {description && (
            <div className='mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6 max-w-2xl'>
              <div className='mt-2 flex items-center text-sm text-gray-500'>
                {description}
              </div>
            </div>
          )}
        </div>
        <div className='mt-4 flex-shrink-0 flex md:mt-0 md:ml-4'>
          {children}
        </div>
      </div>
    </div>
  );
};

export { PageHeader };

/* eslint-disable default-param-last */
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { initializeIcons } from '@fluentui/react';
import './App.css';
import { offsetLimitPagination } from '@apollo/client/utilities';
import Header from './components/header';
import Footer from './components/footer';
import Container from './components/container';
import Boekingen from './pages/boekingen';
import Units from './pages/units';
import Periods from './pages/periods';
import Dashboard from './pages/dashboard';
import Seasons from './pages/seasons';
import Options from './pages/options';

initializeIcons(/* optional base url */);

function App() {
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    isAuthenticated,
    isLoading,
  } = useAuth0();
  // Setup link with data api
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BACKEND_URI
      ? `${process.env.REACT_APP_BACKEND_URI}/graphql`
      : 'http://localhost:4000/graphql',
  });

  const authLink = setContext((_, { headers }) => {
    return getAccessTokenSilently().then((accessToken: string) => {
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      };
    });
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          findManyBookings: {
            ...offsetLimitPagination(['where', 'orderBy']),
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({});
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100VH',
          }}
        >
          <Header />
          <Container>
            <Routes>
              <Route path='/boekingen' element={<Boekingen />} />
              <Route path='/units' element={<Units />} />
              <Route path='/opties' element={<Options />} />
              <Route path='/periodes' element={<Periods />} />
              <Route path='/seizoenen' element={<Seasons />} />
              <Route path='/' element={<Dashboard />} />
            </Routes>
          </Container>

          <div style={{ flex: 1 }} />
          <Footer />
        </div>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;

import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

interface Props {
  title: string;
  description?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  actions?: React.FC | JSX.Element;
  lightDismiss?: boolean;
}

const Slideover: React.FC<Props> = ({
  actions,
  children,
  title,
  description,
  open,
  setOpen,
  lightDismiss,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 overflow-hidden'
        onClose={lightDismiss ? setOpen : () => ({})}
      >
        <div className='absolute inset-0 overflow-hidden z-20'>
          <Dialog.Overlay className='absolute inset-0' />

          <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
            <Transition.Child
              as={Fragment}
              enter='transform transition ease-in-out duration-500 sm:duration-700'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in-out duration-500 sm:duration-700'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
            >
              <div className='pointer-events-auto w-screen max-w-2xl'>
                <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                  <div className='flex-1'>
                    {/* Header */}
                    <div className='bg-gray-50 px-4 py-6 sm:px-6'>
                      <div className='flex items-start justify-between space-x-3'>
                        <div className='space-y-1'>
                          <Dialog.Title className='text-lg font-medium text-gray-900'>
                            {' '}
                            {title}{' '}
                          </Dialog.Title>
                          <p className='text-sm text-gray-500'>{description}</p>
                        </div>
                        <div className='flex h-7 items-center'>
                          <button
                            type='button'
                            className='text-gray-400 hover:text-gray-500'
                            onClick={() => setOpen(false)}
                          >
                            <span className='sr-only'>Close panel</span>
                            <XIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
                      {children}
                    </div>
                  </div>

                  {actions}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { Slideover };

import React from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Select: React.FC<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
> = ({ className, ...props }) => {
  return (
    <select
      className={classNames(
        'max-w-lg block focus:ring-red-500 focus:border-red-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
        props.disabled ? 'bg-gray-100 text-gray-500' : '',
        className || '',
      )}
      {...props}
    />
  );
};

export { Select };

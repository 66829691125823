import { gql } from '@apollo/client';

export const GET_HIGHSEASONPERIOD = gql`
  query findOneHighSeasonPeriod($where: HighSeasonPeriodWhereUniqueInput) {
    findOneHighSeasonPeriod(where: $where) {
      id
      createdAt
      updatedAt
      start
      end
      title
      additionalPrice
      discount
      units {
        title
        id
      }
    }
  }
`;

export const GET_HIGHSEASONPERIODS = gql`
  query findManyHighSeasonPeriods($query: HighSeasonPeriodFindArgs) {
    findManyHighSeasonPeriods(query: $query) {
      id
      createdAt
      updatedAt
      start
      end
      title
      additionalPrice
      discount
      units {
        title
        id
      }
    }
  }
`;

export const CREATE_HIGHSEASONPERIOD = gql`
  mutation createHighSeasonPeriod($data: HighSeasonPeriodCreateInput) {
    createHighSeasonPeriod(data: $data) {
      id
      createdAt
      updatedAt
      start
      end
      title
      additionalPrice
      discount
      units {
        title
        id
      }
    }
  }
`;

export const UPDATE_HIGHSEASONPERIOD = gql`
  mutation updateHighSeasonPeriod(
    $data: HighSeasonPeriodUpdateInput
    $where: HighSeasonPeriodWhereUniqueInput
  ) {
    updateHighSeasonPeriod(data: $data, where: $where) {
      id
      createdAt
      updatedAt
      start
      end
      title
      additionalPrice
      discount
      units {
        title
        id
      }
    }
  }
`;

export const DELETE_HIGHSEASONPERIOD = gql`
  mutation deleteHighSeasonPeriod($where: HighSeasonPeriodWhereUniqueInput) {
    deleteHighSeasonPeriod(where: $where) {
      count
    }
  }
`;

export const DELETE_HIGHSEASONPERIODS = gql`
  mutation deleteHighSeasonPeriods($where: HighSeasonPeriodWhereInput) {
    deleteHighSeasonPeriods(where: $where) {
      count
    }
  }
`;

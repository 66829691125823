import React from 'react';

const Footer: React.FC = ({ ...props }) => {
  return (
    <div
      style={{ textAlign: 'center', marginTop: '15px', marginBottom: '15px' }}
      {...props}
    >
      Developed by iSmart
    </div>
  );
};

export default Footer;
export { Footer };

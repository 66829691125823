import { gql } from '@apollo/client';

export const GET_OPTION = gql`
  query findOneOption($where: OptionWhereUniqueInput) {
    findOneOption(where: $where) {
      id
      name
      fee
      type
      description
    }
  }
`;

export const GET_OPTIONS = gql`
  query findManyOptions($query: OptionFindArgs) {
    findManyOptions(query: $query) {
      id
      name
      fee
      type
      description
    }
  }
`;

export const CREATE_OPTION = gql`
  mutation createOption($data: OptionCreateInput) {
    createOption(data: $data) {
      id
      name
      fee
      type
      description
    }
  }
`;

export const UPDATE_OPTION = gql`
  mutation updateOption(
    $data: OptionUpdateInput
    $where: OptionWhereUniqueInput
  ) {
    updateOption(data: $data, where: $where) {
      id
      name
      fee
      type
      description
    }
  }
`;

export const DELETE_OPTION = gql`
  mutation deleteOption($where: OptionWhereUniqueInput) {
    deleteOption(where: $where) {
      count
    }
  }
`;

export const DELETE_OPTIONS = gql`
  mutation deleteOptions($where: OptionWhereInput) {
    deleteOptions(where: $where) {
      count
    }
  }
`;

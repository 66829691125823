/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { SearchIcon, ExclamationIcon } from '@heroicons/react/solid';
import _ from 'lodash';
import { Input } from '../components/input';
import useDebounce from '../components/hooks/useDebounce';
import { Button } from '../components/button';
import { PageHeader } from '../components/pageheader';
import { Table, IColumn } from '../components/table';
import { Slideover } from '../components/slideover';
import { Notification } from '../components/notification';
import { Dialog } from '../components/dialog';
import { IPeriod } from '../types';
import { resolveEndOfPeriod } from '../utils';
import {
  GET_PERIODS,
  CREATE_PERIOD,
  UPDATE_PERIOD,
  DELETE_PERIOD,
} from '../graphql/period';
import { INavItem } from '../components/navbar';

const dayArray = [
  { id: 1, label: 'Maandag' },
  { id: 2, label: 'Dinsdag' },
  { id: 3, label: 'Woensdag' },
  { id: 4, label: 'Donderdag' },
  { id: 5, label: 'Vrijdag' },
  { id: 6, label: 'Zaterdag' },
  { id: 0, label: 'Zondag' },
];

interface ICreateUpdate {
  period?: IPeriod;
  handleChange?: React.Dispatch<React.SetStateAction<IPeriod | undefined>>;
}

const CreateUpdateForm: React.FC<ICreateUpdate> = ({
  period,
  handleChange = () => ({}),
}) => {
  const onChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = event;
    const { value, id } = target;

    handleChange(prevState => {
      let updatedPeriod: IPeriod | undefined = _.cloneDeep(prevState);

      if (!updatedPeriod)
        updatedPeriod = {
          name: '',
          duration: 0,
          startDay: 1,
          startDays: '[1]',
        };

      updatedPeriod = {
        ...updatedPeriod,
        [id]: value,
      };
      return updatedPeriod;
    });
  };

  const periodChecked = (day: number, valuesJSON: string | undefined) => {
    if (valuesJSON && valuesJSON.length > 0) {
      const values: number[] = JSON.parse(valuesJSON);

      if (values.includes(day)) return true;
    }

    return false;
  };

  const handleCheckboxChange = (id: number, checked: boolean) => {
    handleChange(prevState => {
      let updatedPeriod: IPeriod | undefined = _.cloneDeep(prevState);

      const newValue: number[] = [];
      const currentValues: number[] =
        prevState && prevState.startDays ? JSON.parse(prevState.startDays) : [];

      if (!updatedPeriod)
        updatedPeriod = {
          name: '',
          duration: 0,
          startDay: 1,
          startDays: '[1]',
        };

      for (let i = 0; i < currentValues.length; i += 1) {
        if (currentValues[i] === id) {
          if (checked) newValue.push(currentValues[i]);
        } else {
          newValue.push(currentValues[i]);
        }
      }

      if (!currentValues.includes(id) && checked) {
        newValue.push(id);
      }

      updatedPeriod = {
        ...updatedPeriod,
        startDays: JSON.stringify(newValue),
      };
      return updatedPeriod;
    });
  };

  return (
    <form className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Naam: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='name'
            id='name'
            value={period && period.name ? period.name : ''}
            onChange={onChangeInput}
            type='text'
          />
        </div>
      </div>
      <div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
          >
            {' '}
            Aantal dagen: *{' '}
          </label>
        </div>
        <div className='sm:col-span-2'>
          <Input
            name='duration'
            id='duration'
            type='number'
            value={period && period.duration ? period.duration : ''}
            onChange={onChangeInput}
          />
        </div>
      </div>
      <div className='space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5'>
        <fieldset>
          <legend className='text-lg font-medium text-gray-900'>
            Mogelijk aankomst
          </legend>
          <div className='mt-4 border-t border-b border-gray-200 divide-y divide-gray-200'>
            {dayArray.map(item => (
              <div key={item.id} className='relative flex items-start py-4'>
                <div className='min-w-0 flex-1 text-sm'>
                  <label
                    htmlFor={`person-${item.id}`}
                    className='font-medium text-gray-700 select-none'
                  >
                    {item.label}
                  </label>
                </div>
                <div className='ml-3 flex items-center h-5'>
                  <input
                    id={`day-${item.id}`}
                    name={`day-${item.id}`}
                    defaultChecked={
                      period ? periodChecked(item.id, period?.startDays) : false
                    }
                    type='checkbox'
                    onChange={e => {
                      const { target } = e;
                      const { checked } = target;

                      handleCheckboxChange(item.id, checked);
                    }}
                    className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                  />
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    </form>
  );
};

export const Periods: React.FC = ({ ...props }) => {
  const [open, setOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [error, setError] = useState<any>();

  const [periods, setPeriods] = useState<IPeriod[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [selection, setSelection] = useState<IPeriod | undefined>();
  const [sorting, setSorting] = useState<
    { key: string; direction: string } | undefined
  >({ key: 'name', direction: 'asc' });
  const [debouncedFilter, setDebouncedFilter] = useState<string>('');

  useDebounce(filter, 500, (value: any) => {
    setDebouncedFilter(value);
  });

  const { refetch, loading } = useQuery(GET_PERIODS, {
    variables: {
      query: {
        where: {
          OR: [{ name: { contains: debouncedFilter } }],
        },
        orderBy: sorting ? { [sorting.key]: sorting.direction } : undefined,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (x: any) => {
      if (x && x.findManyPeriods) setPeriods(x.findManyPeriods);
    },
    onError: err => setError(err),
  });

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column?: IColumn,
  ): void => {
    if (column && column.fieldName) {
      setSorting({
        key: column.fieldName,
        direction: column.isSortedDescending ? 'asc' : 'desc',
      });
    }
  };

  const editItem = (item: IPeriod) => {
    setSelection(item);
    setOpen(true);
  };

  const deleteItem = (item: IPeriod) => {
    setSelection(item);
    setOpenDialog(true);
  };

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      render: (item: IPeriod) => {
        return item.name;
      },
      onHeaderClick: onColumnClick,
      isSorted: sorting && sorting.key === 'name',
      isSortedDescending: sorting && sorting.direction === 'desc',
    },
    {
      key: 'column2',
      name: 'Aankomst',
      fieldName: 'start',
      render: (item: IPeriod) => {
        const renderArray = [];
        if (item.startDays && item.startDays.length > 0) {
          const parsedValues = JSON.parse(item.startDays);

          for (let i = 0; i < parsedValues.length; i += 1) {
            const value = parsedValues[i];
            let dayIndex = 0;
            for (let l = 0; l < dayArray.length; l += 1) {
              if (dayArray[l].id === value) {
                dayIndex = l;
              }
            }
            const lastDay = i === parsedValues.length - 1;
            renderArray.push(
              <span>
                {dayArray[dayIndex].label}
                {!lastDay && ', '}
              </span>,
            );
          }
        }
        return <span>{renderArray}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Vertrek',
      fieldName: 'end',
      render: (item: IPeriod) => {
        return <span>{resolveEndOfPeriod(item.startDay, item.duration)}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Duur',
      fieldName: 'duration',
      render: (item: IPeriod) => {
        return item.duration;
      },
    },
    {
      key: 'column5',
      name: '',
      render: (item: IPeriod) => {
        return (
          <div className='space-x-3'>
            <button
              className='text-indigo-600 hover:text-indigo-900'
              type='button'
              onClick={() => editItem(item)}
            >
              bewerken<span className='sr-only'>, {item.name}</span>
            </button>
            <button
              className='text-indigo-600 hover:text-indigo-900'
              type='button'
              onClick={() => deleteItem(item)}
            >
              verwijderen<span className='sr-only'>, {item.name}</span>
            </button>
          </div>
        );
      },
    },
  ];

  const [createPeriod] = useMutation(CREATE_PERIOD, {
    onError: err => {
      setError(err);
    },
    onCompleted: d => {
      setOpen(false);
      refetch();
    },
  });

  const [updatePeriod] = useMutation(UPDATE_PERIOD, {
    onError: err => {
      setError(err);
    },
    onCompleted: d => {
      setOpen(false);
      refetch();
    },
  });

  const [deletePeriod] = useMutation(DELETE_PERIOD, {
    onError: err => {
      setError(err);
    },
    onCompleted: d => {
      setOpenDialog(false);
      refetch();
    },
  });

  const save = React.useCallback(() => {
    if (selection) {
      if (selection.id) {
        updatePeriod({
          variables: {
            data: {
              name: selection.name,
              startDay: Number(selection.startDay),
              startDays: selection.startDays,
              duration: Number(selection.duration),
            },
            where: {
              id: selection.id,
            },
          },
        });
      } else {
        createPeriod({
          variables: {
            data: {
              name: selection.name,
              startDay: Number(selection.startDay),
              startDays: selection.startDays,
              duration: Number(selection.duration),
            },
          },
        });
      }
    }
  }, [selection, createPeriod, updatePeriod]);

  const formComplete = React.useCallback((period: IPeriod | undefined) => {
    if (!period) return false;

    let valid = true;
    if (!period.name || period.name.length < 2) valid = false;
    if (!period.startDay && period.startDay !== 0) valid = false;
    if (!period.duration || period.duration < 1) valid = false;

    return valid;
  }, []);

  const onRenderFooterContent = React.useCallback(
    () => (
      <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
        <div className='flex justify-end space-x-3'>
          <Button
            secondary
            size='lg'
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            size='lg'
            onClick={() => {
              save();
            }}
            className='mr-1'
            disabled={!formComplete(selection)}
          >
            Save
          </Button>
        </div>
      </div>
    ),
    [setOpen, formComplete, selection, save],
  );
  const confirmDeleteItem = () => {
    if (selection) {
      deletePeriod({
        variables: {
          where: {
            id: selection.id,
          },
        },
      });
    }
  };

  const pages: INavItem[] = [
    { name: 'Dashboard', href: '/' },
    { name: 'Periodes', href: '/periodes' },
  ];

  const navigation = [
    {
      name: 'Nieuw',
      onClick: () => {
        setSelection(undefined);
        setOpen(true);
      },
      current: false,
      // disabled: !!(selection && selection.id),
    },
    /* {
      name: 'Bewerken',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faPen} />,
      disabled: !selection || !selection.id,
    },
    {
      name: 'Verwijderen',
      onClick: () => {
        openPanel();
      },
      current: false,
      icon: <FontAwesomeIcon icon={faTrash} />,
      disabled: !selection || !selection.id,
    }, */
  ];

  return (
    <div {...props}>
      <PageHeader
        title='Periodes beheren'
        description='De periodes die hieronder zijn aangegeven duiden het hoogseizoen aan en zullen bijgevolg de prijs van het gedefineerde hoogseizeoen overnemen.'
        pages={pages}
        home={{ href: '/' }}
        loading={loading}
      >
        <div className='flex align-middle'>
          {navigation.map((item, index) => {
            return (
              <Button
                size='lg'
                primary
                onClick={item.onClick}
                // disabled={item.disabled}
                className={index > 0 ? 'ml-2' : ''}
              >
                {item.name}
              </Button>
            );
          })}
          <div className='relative ml-5'>
            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
              <SearchIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              id='search'
              name='search'
              className='block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-redo-500 focus:border-redo-500 sm:text-sm'
              placeholder='Search'
              type='search'
              autoComplete='off'
              onChange={e => {
                const { target } = e;
                const { value } = target;
                setFilter(value);
              }}
            />
          </div>
        </div>
      </PageHeader>
      <Table items={periods} columns={columns} />
      <Notification
        show={!!error}
        dismiss={() => {
          setError(undefined);
        }}
        level='error'
        title='Er ging iets mis'
      >
        {error?.message}
      </Notification>
      <Slideover
        title={
          selection && selection.id ? 'Periode bewerken' : 'Periode toevoegen'
        }
        description='Voeg een nieuwe periode toe of bewerk een bestaande periode.'
        open={open}
        setOpen={setOpen}
        actions={onRenderFooterContent()}
        lightDismiss
      >
        <CreateUpdateForm handleChange={setSelection} period={selection} />
      </Slideover>
      <Dialog
        open={openDialog}
        setOpen={setOpenDialog}
        title='Seizoen verwijderen'
        icon={
          <ExclamationIcon
            className='h-6 w-6 text-red-600'
            aria-hidden='true'
          />
        }
        description={
          <span>
            Je staat op het punt om de geselecteerde periode{' '}
            <strong>{selection?.name} </strong> te verwijderen. Deze actie is
            kan niet worden teruggedraaid.
          </span>
        }
        actions={
          <div className='space-x-3'>
            <Button
              secondary
              style={{ marginBottom: 2 }}
              onClick={e => {
                e.preventDefault();
                confirmDeleteItem();
                // dismissPanel();
              }}
            >
              Verwijderen
            </Button>
          </div>
        }
        lightDismiss
      />
    </div>
  );
};

export default Periods;

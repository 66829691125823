import { Switch } from '@headlessui/react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface ToggleProps {
  dark?: boolean;
  checked: boolean;
  onChange: (value: boolean) => void;
  style?: '1' | '2';
  icons?: boolean;
  label?: string;
  disabled?: boolean;
}

const Toggle: React.FC<ToggleProps> = ({
  dark,
  checked,
  onChange,
  style = '1',
  icons,
  label,
  disabled,
}) => {
  return (
    <Switch.Group
      as='div'
      className={classNames(
        'flex items-center',
        disabled ? 'opacity-75 pointer-events-none' : '',
      )}
    >
      <Switch
        checked={checked}
        onChange={onChange}
        className={classNames(
          'flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 ',
          dark
            ? 'focus:ring-offset-gray-800 focus:ring-white'
            : 'focus:ring-red-500',
        )}
      >
        <span className='sr-only'>Use setting</span>
        <span
          aria-hidden='true'
          className={classNames(
            'pointer-events-none absolute  w-full h-full rounded-md',
            dark ? 'bg-gray-800' : 'bg-white',
          )}
        />
        <span
          aria-hidden='true'
          className={classNames(
            checked ? 'bg-red-600' : 'bg-gray-200',
            'pointer-events-none absolute w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
            style === '1' ? 'h-5' : 'h-4',
          )}
        />
        <span
          aria-hidden='true'
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
          )}
        >
          {icons && (
            <>
              {' '}
              <span
                className={classNames(
                  checked
                    ? 'opacity-0 ease-out duration-100'
                    : 'opacity-100 ease-in duration-200',
                  'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                )}
                aria-hidden='true'
              >
                <svg
                  className='h-3 w-3 text-gray-400'
                  fill='none'
                  viewBox='0 0 12 12'
                >
                  <path
                    d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                    stroke='currentColor'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </span>
              <span
                className={classNames(
                  checked
                    ? 'opacity-100 ease-in duration-200'
                    : 'opacity-0 ease-out duration-100',
                  'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                )}
                aria-hidden='true'
              >
                <svg
                  className='h-3 w-3 text-red-600'
                  fill='currentColor'
                  viewBox='0 0 12 12'
                >
                  <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                </svg>
              </span>
            </>
          )}
        </span>
      </Switch>
      <Switch.Label as='span' className='ml-3'>
        <span className='text-sm font-medium text-gray-900'>{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
};

export { Toggle as Switch };

import React from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface IColumn
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  colsm?: number;
  colmd?: number;
  collg?: number;
}

const Container: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <div
      className={classNames(
        '2xl:max-w-screen-2xl xl:max-w-screen-xl md:max-w-[90%] mx-auto w-full',
        className || '',
      )}
      {...props}
    />
  );
};

const Row: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <div
      className={classNames(`grid grid-cols-12 gap-4`, className || '')}
      {...props}
    />
  );
};

const getSmallGridClass = (number?: number) => {
  switch (number) {
    case 1:
      return 'col-span-1';
    case 2:
      return 'col-span-2';
    case 3:
      return 'col-span-3';
    case 4:
      return 'col-span-4';
    case 5:
      return 'col-span-5';
    case 6:
      return 'col-span-6';
    case 7:
      return 'col-span-7';
    case 8:
      return 'col-span-8';
    case 9:
      return 'col-span-9';
    case 10:
      return 'col-span-10';
    case 11:
      return 'col-span-11';
    case 12:
      return 'col-span-12';
    default:
      return 'col-span-12';
  }
};

const getMediumGridClass = (number?: number) => {
  switch (number) {
    case 1:
      return 'md:col-span-1';
    case 2:
      return 'md:col-span-2';
    case 3:
      return 'md:col-span-3';
    case 4:
      return 'md:col-span-4';
    case 5:
      return 'md:col-span-5';
    case 6:
      return 'md:col-span-6';
    case 7:
      return 'md:col-span-7';
    case 8:
      return 'md:col-span-8';
    case 9:
      return 'md:col-span-9';
    case 10:
      return 'md:col-span-10';
    case 11:
      return 'md:col-span-11';
    case 12:
      return 'md:col-span-12';
    default:
      return 'md:col-span-12';
  }
};

const getLargeGridClass = (number?: number) => {
  switch (number) {
    case 1:
      return 'lg:col-span-1';
    case 2:
      return 'lg:col-span-2';
    case 3:
      return 'lg:col-span-3';
    case 4:
      return 'lg:col-span-4';
    case 5:
      return 'lg:col-span-5';
    case 6:
      return 'lg:col-span-6';
    case 7:
      return 'lg:col-span-7';
    case 8:
      return 'lg:col-span-8';
    case 9:
      return 'lg:col-span-9';
    case 10:
      return 'lg:col-span-10';
    case 11:
      return 'lg:col-span-11';
    case 12:
      return 'lg:col-span-12';
    default:
      return 'lg:col-span-12';
  }
};

const Column: React.FC<IColumn> = ({
  // style,
  colsm,
  colmd,
  collg,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        `${getSmallGridClass(colsm)} ${getMediumGridClass(
          colmd,
        )} ${getLargeGridClass(collg)}`,
        className || '',
      )}
      {...props}
    />
  );
};

export default Container;
export { Container, Row, Column };

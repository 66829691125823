import { gql } from '@apollo/client';

export const GET_AVAILABILITY = gql`
  query getAvailability($where: GetAvailabilityArgs) {
    getAvailability(where: $where) {
      date
      valid
      canLeave
      canArrive
      booked
      available
      reason
    }
  }
`;

export const GET_BOOKING_INFO = gql`
  query getBookingInfo($where: GetBookingInfoArgs) {
    getBookingInfo(where: $where) {
      date
      booked
      temporary
    }
  }
`;

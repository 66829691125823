import { gql } from '@apollo/client';

export const GET_PERIOD = gql`
  query findOnePeriod($where: PeriodWhereUniqueInput) {
    findOnePeriod(where: $where) {
      id
      createdAt
      updatedAt
      name
      startDay
      startDays
      duration
      bookings {
        unit {
          id
          title
        }
        start
        end
        price
        user {
          id
          profile {
            id
            firstName
            lastName
          }
        }
      }
      unitPeriods {
        id
        price
        season {
          id
          title
        }
        period {
          id
          name
        }
        unit {
          id
          title
        }
      }
    }
  }
`;

export const GET_PERIODS = gql`
  query findManyPeriods($query: PeriodFindArgs) {
    findManyPeriods(query: $query) {
      id
      createdAt
      updatedAt
      name
      startDay
      startDays
      duration
      bookings {
        unit {
          id
          title
        }
        start
        end
        price
        user {
          id
          profile {
            id
            firstName
            lastName
          }
        }
      }
      unitPeriods {
        id
        price
        season {
          id
          title
        }
        period {
          id
          name
        }
        unit {
          id
          title
        }
      }
    }
  }
`;

export const CREATE_PERIOD = gql`
  mutation createPeriod($data: PeriodCreateInput) {
    createPeriod(data: $data) {
      id
      createdAt
      updatedAt
      name
      startDay
      startDays
      duration
      bookings {
        unit {
          id
          title
        }
        start
        end
        price
        user {
          id
          profile {
            id
            firstName
            lastName
          }
        }
      }
      unitPeriods {
        id
        price
        season {
          id
          title
        }
        period {
          id
          name
        }
        unit {
          id
          title
        }
      }
    }
  }
`;

export const UPDATE_PERIOD = gql`
  mutation updatePeriod(
    $data: PeriodUpdateInput
    $where: PeriodWhereUniqueInput
  ) {
    updatePeriod(data: $data, where: $where) {
      id
      createdAt
      updatedAt
      name
      startDay
      startDays
      duration
      bookings {
        unit {
          id
          title
        }
        start
        end
        price
        user {
          id
          profile {
            id
            firstName
            lastName
          }
        }
      }
      unitPeriods {
        id
        price
        season {
          id
          title
        }
        period {
          id
          name
        }
        unit {
          id
          title
        }
      }
    }
  }
`;

export const DELETE_PERIOD = gql`
  mutation deletePeriod($where: PeriodWhereUniqueInput) {
    deletePeriod(where: $where) {
      count
    }
  }
`;

export const DELETE_PERIODS = gql`
  mutation deletePeriods($where: PeriodWhereInput) {
    deletePeriods(where: $where) {
      count
    }
  }
`;
